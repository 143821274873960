.wrapper {
  margin-top: 4rem;
}

.section-heading {
  margin-top: 8rem;

  .heading {
    font-size: 52px;
  }
}

.breadcrumb {
  justify-content: center;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #ffffff;
  border-radius: .25rem;
}
.breadcrumb-item.active {
  color: #da0b4e;
}
