.carousel-control-prev-icon,
.carousel-control-next-icon{
  filter: invert(1)

}
.carousel-indicators li {
  display: inline-block;
  width: 30px;
  height: 10px;
  margin: 10px;
  text-indent: 0;
  cursor: pointer;
  border: solid 1px;
  background-color: #ffffffb7;
  // box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.5);
}
.carousel-indicators .active {
  width: 30px;
  height: 10px;
  margin: 10px;
  background-color: #00000036;
}