.testimonial-section {
  background:url("./../assets/custom/img/testimonial.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


}
.testimonial-detail{
  display:contents
}