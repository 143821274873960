/*
* Theme Name: LearnUp - Education HTML Template
* Author: Themezhub
* Version: 1.0
* Last Change: 08 July 2020
  Author URI    : https://themezhub.com/
-------------------------------------------------------------------*/
/*------------- List Of Table -----------
# Fonts
# Preloader
# Global Settings
# Header Navigation Styles
# Homes Banner
# Education Elements
# Education Common Page & Elements
# Blog Styles
# Shop Page Designs
# Dashboard Design 
# Footer Start   
# Responsiveness Designs
------------- List Of Table ----------*/

/* CSS Document */
/* @import url(../css/plugins/animation.css);
@import url(../css/plugins/bootstrap.min.css);
@import url(../css/plugins/date-picker.css);
@import url(../css/plugins/dropzone.css);
@import url(../css/plugins/light-box.css);
@import url(../css/plugins/ion.rangeSlider.min.css);
@import url(../css/plugins/magnifypopup.css);
@import url(../css/plugins/select2.css);
@import url(../css/plugins/slick.css);
@import url(../css/plugins/slick-theme.css);
@import url(../css/plugins/themify.css);
@import url(../css/plugins/morris.css);
@import url(../css/plugins/line-icons.css);
@import url(../css/plugins/iconfont.css);
@import url(../css/plugins/font-awesome.css);
@import url(../css/plugins/flaticon.css); */

/*------------------------------------
	Fonts
------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');

/*------------------------------------
	Preloader
------------------------------------*/
.preloader {
  background-color: #ffffff;
  background-image: url("../img/loader.svg");
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99999;
}
i.req {
    color: #e41515;
}
ul{
	padding:0;
	margin:0;
}

/*--- end of preloader ---*/
/*------------------------------------------------------------
	Global Settings
-------------------------------------------------------------*/
* {
  outline: none; }
.modal-open {
    overflow: hidden !important;
}
body {
	background: #ffffff;
	color:#647b9c;
	font-size:15px;
	font-family: 'Muli', sans-serif;
	margin: 0;
	overflow-x: hidden !important;
	font-weight: 400;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff; }
a {
    color:#4a5682;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover,
a:focus {
  text-decoration: none; }

a.link {
  color:#da0b4e; }
a.link:hover, a.link:focus, a:hover, a:focus {
    color:#da0b4e; 
}
a.text-success:focus, a.text-success:hover {
    color:#2ea033 !important;
}
a.text-primary:focus, a.text-primary:hover {
    color:#da0b4e !important;
}
a.text-warning:focus, a.text-warning:hover {
    color:#ff9b20 !important;
}
a.text-danger:focus, a.text-danger:hover {
    color:#f33066 !important;
}
a.white-link {
  color: #ffffff; }
  a.white-link:hover, a.white-link:focus {
    color: #263238; }

.img-responsive {
  width: 100%;
  height: auto;
  display: inline-block; }

.img-rounded {
  border-radius: 4px; }

html body .mdi:before,
html body .mdi-set {
  line-height: initial;
}
section{
	padding:80px 0 80px;
	position:relative;
}
p {
    line-height:1.8;
}
	
.gray-bg{
	/* background:#f7f7f7 url(../img/pat.png); */
	background-repeat:repeat;
    background-position: center;
}
.gray{
	background:#f4f5f7;
}
.gray-simple{
	background:#f7f9fc;
}
.mid-bg{
	background:#f9f4ef;
}
.bg-1{
	background:#f7d0cb;
}
.bg-2{
	background:#dde9ed;
}
.tbl {
    display: table;
} 
  .full-height {
    height: 100%;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}
.no-ul-list{
	padding:0;
}
.no-ul-list li{
	list-style:none;
}
.progress {
    margin-bottom: 1.5em;
}
.full-width{
	width:100%;
}
p, ul, ol, dl, dt, dd, blockquote, address {
    margin: 0 0 10px;
}
.owl-carousel .item {
    padding: 0 15px;
}
.explore-content p {
    font-size: 19px;
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
}
.explore-content h1, .explore-content h2{
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
}
.shadow-0{
	box-shadow:none !important;
}
.dn {
    display: none;
}
/*------------ GLobal Settings: Heading-------------*/ 
h1,
h2,
h3,
h4,
h5,
h6 {
	color:#2D3954;
	font-weight:600;
	text-transform: capitalize;
	font-family:'Poppins', sans-serif;
}
.cl_2{
	color:#242f65;
}
h5,
h6 {
	color:#2D3954;  
}

.font-2{
  font-family:'Poppins', sans-serif;
}

h1 {
  line-height: 40px;
  font-size: 36px; }

h2 {
  line-height: 36px;
  font-size: 30px; }

h3 {
  line-height: 30px;
  font-size: 24px; }

h4 {
  line-height: 26px;
  font-size: 21px; }

h5 {
  line-height: 22px;
  font-size: 18px;
}

h6 {
  line-height: 20px;
  font-size: 16px;
}
.lead{
	font-size:20px !important;
}
.lead-i{
    font-family: 'Work Sans', sans-serif;
	font-size:22px !important;
	font-style:italic;
}
.display-5 {
  font-size: 3rem; }

.display-6 {
  font-size: 36px; }

.display-7 {
  font-size: 26px; }

.box {
  border-radius: 4px;
  padding: 10px; }

html body .dl {
  display: inline-block; }

html body .db {
  display: block; }

.no-wrap td,
.no-wrap th {
  white-space: nowrap; }

.circle {
  border-radius: 100%; }

.theme-cl{
	color:#da0b4e;
}
.theme-bg{
	background:#da0b4e;
}
.devided {
    position: relative;
    border-top: 1px solid #eaedf5;
}
.devided span {
    text-align: center;
    position: relative;
    padding: 5px 20px 6px;
    border: 1px solid #eaedf5;
    border-radius: 50px;
    line-height: 0;
    background: #ffffff;
    top: -12px;
}
/*------------ Global Settings: Ul List Style ----------------*/
ul.list-style {
    padding: 0;
    margin: 0;
}
ul.list-style li {
    margin-bottom: 1.2em;
    line-height: 1.5;
    list-style: none;
    padding-left:30px;
    position: relative;
}
ul.list-style li:before {
    content: "\e64c";
    position: absolute;
    left: 0;
    font-size: 16px;
    top: 0;
    color: #da0b4e;
    font-family: 'themify';
}
ul.list-style.style-2 li:before {
    content: "\e628" !important;
    color:#0273ff;
}

/*------------ Global Settings: Cutom Height ----------------*/
.ht-50{
	height:50px;
}
.ht-60{
	height:60px;
}
.ht-70{
	height:70px;
}
.ht-80{
	height:80px;
}
.ht-80{
	height:80px;
}
.ht-100{
	height:100px;
}
.ht-110{
	height:110px;
}
.ht-120{
	height:120px;
}
.ht-130{
	height:130px;
}
.ht-140{
	height:140px;
}
.ht-150{
	height:150px;
}
.ht-160{
	height:160px;
}
.ht-170{
	height:170px;
}
.ht-180{
	height:180px;
}
.ht-190{
	height:190px;
}
.ht-200{
	height:200px;
}
.ht-100{
	height:100%;
	min-height:580px;
}
.h-100{
	height:100vh;
	min-height:580px;
}
.hts-100{
	height:100%;
}
blockquote {
    font-size: 1.78571429em;
    line-height: 1.5em;
    padding: 0;
    margin: 0;
    border-left: 0;
}
blockquote {
    font-style: italic;
}
blockquote:not(:last-child) {
    margin-bottom: 1.04em;
}
/*------------ Global Settings: Common & List ----------------*/
ul:last-child {
  margin: 0;
}
.list-inline li {
  padding: 0 1em;
  margin-left: 0;
}
.list-inline li:first-child {
  padding-left: 0;
}
.list-inline li:last-child {
  padding-right: 0;
}
.list-inline.list-inline--narrow li {
  padding: 0 .5em;
}
.list-inline.list-inline--wide li {
  padding: 0 2em;
}
.color--white {
  color: #fff !important;
}
.color--dark {
  color: #252525 !important;
}
.color--success {
  color: #4ebf56 !important;
}
.color--error {
  color: #e23636 !important;
}
.bg--dark {
  background:#1f2025 !important;
}
.bg--dark:not(.nav-bar):not(.bar) {
  color: #ffffff;
}
.bg--dark .boxed:not([class*='bg-']), .imagebg:not(.image--light) .boxed:not([class*='bg-']), .bg--dark .large-features-2, .bg--dark .pr-table-box,
.bg--dark .veticle-large-features-box, .bg--dark .large-features-box, .bg--dark .packages_wrapping {
    background: none;
    color: #fff;
}
.bg--dark .boxed:not([class*='bg-']).boxed--border, .imagebg:not(.image--light) .boxed:not([class*='bg-']).boxed--border {
    border-color:#2a2b31;
}

.bg--dark:not(.nav-bar):not(.bar) h1,
.bg--dark:not(.nav-bar):not(.bar) h2,
.bg--dark:not(.nav-bar):not(.bar) h3,
.bg--dark:not(.nav-bar):not(.bar) h4,
.bg--dark:not(.nav-bar):not(.bar) h5,
.bg--dark:not(.nav-bar):not(.bar) h6,
.bg--dark:not(.nav-bar):not(.bar) i,
.bg--dark:not(.nav-bar):not(.bar) span:not(.btn__text),
.bg--dark:not(.nav-bar):not(.bar) p {
  color: #ffffff;
}
.bg--dark:not(.nav-bar):not(.bar) a:not(.btn) {
  color: #fff;
}
.bg--site {
  background: #ffffff;
}
.bg--primary p,
.bg--primary span,
.bg--primary ul,
.bg--primary a:not(.btn) {
  color: #fff;
}
.bg--primary h1,
.bg--primary h2,
.bg--primary h3,
.bg--primary h4,
.bg--primary h5,
.bg--primary h6,
.bg--primary i {
  color: #fff;
}
.bg--primary .color--primary {
  color: #fff !important;
}
.bg--light {
  background: #fff;
}
.bg--light p,
.bg--light span,
.bg--light ul,
.bg--light a:not(.btn) {
  color: #666666;
}
.bg--light h1,
.bg--light h2,
.bg--light h3,
.bg--light h4,
.bg--light h5,
.bg--light h6,
.bg--light i {
  color: #252525;
}
.bg--error {
  background: #e23636;
}
.bg--success {
  background: #4ebf56;
}
.imagebg:not(.image--light) .bg--light p,
.imagebg:not(.image--light) .bg--light span,
.imagebg:not(.image--light) .bg--light ul,
.imagebg:not(.image--light) .bg--light a:not(.btn) {
  color: #666666;
}
.imagebg:not(.image--light) .bg--light h1,
.imagebg:not(.image--light) .bg--light h2,
.imagebg:not(.image--light) .bg--light h3,
.imagebg:not(.image--light) .bg--light h4,
.imagebg:not(.image--light) .bg--light h5,
.imagebg:not(.image--light) .bg--light h6,
.imagebg:not(.image--light) .bg--light i {
  color: #252525;
}
.imagebg:not(.image--light) .bg--secondary {
  background: rgba(250, 250, 250, 0.2);
}
.image-bg:not(.image-light) *:not(a) {
  color: #fff;
}
.color--facebook {
  color: #3b5998 !important;
}
.color--twitter {
  color: #00aced !important;
}
.color--googleplus {
  color: #dd4b39 !important;
}
.color--instagram {
  color: #125688 !important;
}
.color--pinterest {
  color: #cb2027 !important;
}
.color--dribbble {
  color: #ea4c89 !important;
}
.color--behance {
  color: #053eff !important;
}
.color--linkedin {
  color: #1685b9 !important;
}
.bg--facebook {
  background: #3b5998 !important;
  color: #fff !important;
}
.bg--twitter {
  background: #00aced !important;
  color: #fff !important;
}
.bg--googleplus {
  background: #dd4b39 !important;
  color: #fff !important;
}
.bg--instagram {
  background: #125688 !important;
  color: #fff !important;
}
.bg--pinterest {
  background: #cb2027 !important;
  color: #fff !important;
}
.bg--dribbble {
  background: #ea4c89 !important;
  color: #fff !important;
}
.bg--behance {
  background: #053eff !important;
  color: #fff !important;
}
.bg--linkedin {
  background:#1685b9 !important;
  color: #fff !important;
}
.height-100, .height-90, .height-80, .height-70, .height-60, .height-50, .height-40, .height-30, .height-20, .height-10 {
    height: auto;
    padding: 5em 0;
}
/*---------------- Global Element: data-overlay --------------*/
[data-overlay] {
  position: relative;
}
[data-overlay]:before {
  position: absolute;
  content: '';
  background:#052044;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.theme-ov[data-overlay]:before {
    background:linear-gradient( 109.7deg,  rgba(229,0,57,1) 1.8%, rgba(162,91,182,1) 90.2% );
}
[data-overlay] *:not(.container):not(.bg-img-holder) {
  z-index: 2;
}
[data-overlay].image--light:before {
  background: #fff;
}
[data-overlay].bg--primary:before {
  background: #4a90e2;
}
[data-overlay="1"]:before {
  opacity: 0.1;
}
[data-overlay="2"]:before {
  opacity: 0.2;
}
[data-overlay="3"]:before {
  opacity: 0.3;
}
[data-overlay="4"]:before {
  opacity: 0.4;
}
[data-overlay="5"]:before {
  opacity: 0.5;
}
[data-overlay="6"]:before {
  opacity: 0.6;
}
[data-overlay="7"]:before {
  opacity: 0.7;
}
[data-overlay="8"]:before {
  opacity: 0.8;
}
[data-overlay="9"]:before {
  opacity: 0.9;
}
[data-overlay="10"]:before {
  opacity: 1;
}
[data-overlay="0"]:before {
  opacity: 0;
}

.imagebg {
  position: relative;
}
.imagebg .container {
  z-index: 2;
}
.imagebg .container:not(.pos-absolute) {
  position: relative;
}
.imagebg:not(.image--light) h1,
.imagebg:not(.image--light) h2,
.imagebg:not(.image--light) h3,
.imagebg:not(.image--light) h4,
.imagebg:not(.image--light) h5,
.imagebg:not(.image--light) h6,
.imagebg:not(.image--light) p,
.imagebg:not(.image--light) ul,
.imagebg:not(.image--light) blockquote {
  color: #fff;
}
.imagebg:not(.image--light) .bg--white h1,
.imagebg:not(.image--light) .bg--white h2,
.imagebg:not(.image--light) .bg--white h3,
.imagebg:not(.image--light) .bg--white h4,
.imagebg:not(.image--light) .bg--white h5,
.imagebg:not(.image--light) .bg--white h6 {
  color: #252525;
}
.imagebg:not(.image--light) .bg--white p,
.imagebg:not(.image--light) .bg--white ul {
  color: #666666;
}
div[data-overlay] h1,
div[data-overlay] h2,
div[data-overlay] h3,
div[data-overlay] h4,
div[data-overlay] h5,
div[data-overlay] h6 {
  color: #fff;
}
div[data-overlay] p{
  color: #fff;
}
.parallax {
  overflow: hidden;
}
/**! 07. Icons **/
.icon {
  line-height: 1em;
  font-size: 3.14285714em;
}
.icon--xs {
  font-size: 1em;
}
.icon--sm {
  font-size: 2.35714286em;
}
.icon--lg {
  font-size: 5.57142857em;
}
.imagebg:not(.image--light) span {
    color: #fff;
}
/*------------ Global Settings: Spinner ----------------*/

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
    border: 3px solid;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width:30px;
  height:30px;
  vertical-align: text-bottom;
  background-color:#ffffff;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width:30px;
  height:30px;
}

/*------------ GLobal Settings: Blockquote -------------*/ 
.clear {
  clear: both; }

ol li {
  margin: 5px 0; }

/*------------ GLobal Settings: Common Bg Style -------------*/
.bg-cover{
    background-size: cover !important;
    background-position: center !important;
}	
.image-bg{
    background-size: cover !important;
    background-position: center !important;
}
.default-bg{
	background-size:initial !important;
    background-position:initial !important;
}
.image-bg-wrap {
    background-size: cover !important;
    background-position: center !important;
    position: relative;
}
.image-bg-wrap:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #182538;
    display: block;
    content: "";
    opacity: 0.8;
}

/*------------ GLobal Settings: vertical alignment -------------*/
html body .vt {
  vertical-align: top; }

html body .vm {
  vertical-align: middle; }

html body .vb {
  vertical-align: bottom; }

/*-------------- GLobal Settings: Opacity ----------------*/
.op-8 {
  opacity: 0.8; }

.op-7 {
  opacity: 0.7; }

.op-5 {
  opacity: 0.5; }

.op-3 {
  opacity: 0.3; }


/*-------------- GLobal Settings: font weight ----------------*/
html body .font-bold {
  font-weight: 600; }

html body .font-normal {
  font-weight: normal; }
 
html body .f-style {
 font-style:normal;
}
/*-------------- GLobal Settings: Border ----------------*/
html body .b-0 {
  border: none !important; }

html body .b-r {
  border-right: 1px solid #e0ecf5 !important; }

html body .b-l {
  border-left: 1px solid #e0ecf5 !important; }

html body .b-b {
  border-bottom: 1px solid #e0ecf5 !important; }

html body .b-t {
  border-top: 1px solid #e0ecf5 !important; }

html body .b-all {
  border: 1px solid #e0ecf5 !important; }

/*-------------- GLobal Settings: Text Colors ----------------*/
.text-white {
  color: #ffffff !important; }

.text-danger {
  color: #f33066 !important; }

.text-muted {
  color: #8d97ad !important; }

.text-warning {
  color: #ff9b20 !important; }

.text-success {
  color:#2ea033 !important; }

.text-info {
  color:#03a98a !important; }

.text-inverse {
  color: #3e4555 !important; }

html body .text-blue {
  color: #02bec9; }

html body .text-purple {
  color: #7460ee; }

html body .text-primary {
  color: #da0b4e !important; }

html body .text-megna {
  color: #1dc8cd; }

html body .text-dark {
  color: #8d97ad; }

html body .text-themecolor {
  color: #da0b4e; }

/*------------------------ Global Settings: Lists ------------------*/
.lists-4, .lists-3, .lists-2, .lists-1 {
    margin: 0;
    padding: 0;
}
.lists-4 li, .lists-3 li, .lists-2 li, .lists-1 li {
    list-style: none;
    margin: 1px 0 14px;
    line-height: 27px;
    margin-left: 22px;
    position: relative;
}
.lists-4 li:before, .lists-3 li:before, .lists-2 li:before, .lists-1 li:before {
    font-family: 'themify';
    margin: 0;
    position: relative;
    color: #66676b;
    float: left;
    margin-left: -22px;
    display: block;
}
.lists-4.color li:before, .lists-3.color li:before, .lists-2.color li:before, .lists-1.color li:before{
	color:#D70040;
}
.lists-1 li:before {
    content: "\e71b";
    font-size: 13px;
}
.lists-2 li:before {
    content: "\e724";
    font-size: 13px;
}
.lists-3 li:before {
    content: "\e64c";
    font-size: 13px;
}
.lists-4 li:before {
    content:"\e65d";
    font-size: 13px;
}
/*------------- Global Settings: Avater --------------------*/
img.avatar-30 {
    max-width: 30px;
}
img.avatar-50 {
    max-width: 50px;
}
img.avatar-40 {
    max-width:40px;
}
img.avatar-60 {
    max-width:60px;
}
img.avatar-70 {
    max-width:70px;
}
/*-------------- Global Settings: Alerts & Notification --------------*/
.alert-primary {
    color: #0055ff;
    background-color: #eaf1ff;
    border-color: #eaf1ff;
}
.alert-success {
    color:#2ea033;
    background-color: #e9ffeb;
    border-color: #e9ffeb;
}
.alert-warning {
    color: #ff9b20;
    background-color: #fff5e9;
    border-color: #fff5e9;
}
.alert-info {
    color: #08a7c1;
    background-color: #effdff;
    border-color: #effdff;
}
.alert-danger {
    color: #f33066;
    background-color: #ffe9e9;
    border-color: #ffe9e9;
}
.alert-dark {
    color: #3e4758;
    background-color: #eff4ff;
    border-color: #eff4ff;
}
.alert-secondary {
    color: #4b5d6f;
    background-color: #d6dfe8;
    border-color: #d6dfe8;
}
/*-------------- GLobal Settings: Background Colors ----------------*/
.bg-primary {
  background-color: #da0b4e !important; }

.bg-success {
  background-color:#2ea033 !important; }

.bg-info {
  background-color:#1ac790 !important; }

.bg-warning {
  background-color: #ff9b20 !important; }

.bg-danger {
  background-color: #f33066 !important; }

.bg-orange {
  background-color: #ec2828 !important; }

.bg-yellow {
  background-color: #fed700; }

.bg-facebook {
  background-color: #3b5a9a; }

.bg-twitter {
  background-color: #56adf2; }

html body .bg-megna {
  background-color: #1dc8cd; }

html body .bg-theme {
  background-color: #da0b4e; }

html body .bg-inverse {
  background-color:#374158; }

html body .bg-purple {
  background-color: #7460ee; }

html body .bg-light {
  background-color: #f4f8fa !important; }

html body .bg-light-primary {
  background-color: #f1effd; }

html body .bg-light-success {
  background-color: #e8fdeb; }

html body .bg-light-info {
  background-color: #cfecfe; }

html body .bg-light-extra {
  background-color: #ebf3f5; }

html body .bg-light-warning {
  background-color: #fff8ec; }

html body .bg-light-danger {
  background-color: #f9e7eb; }

html body .bg-light-inverse {
  background-color: #f6f6f6; }

html body .bg-light {
  background-color:#f5f7f9; }

html body .bg-white {
  background-color: #ffffff; }
 
html body .bg-whites {
  background-color: #f4f5f7; }
  
html body .bg-red {
  background-color: #e21137; }
  
html body .bg-green {
  background-color: #4caf50; }
  
html body .bg-sky {
  background-color: #20b2c1; }
  
html body .bg-blue {
  background-color: #03a9f4; }

html body .bg-dark-blue{
	background-color:#192c48;
}

/*-------------- GLobal Settings: Labels ----------------*/
.label {
  padding: 3px 15px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  font-size: 75%; }

.label-rounded {
  border-radius: 60px; }

.label-custom {
  background-color: #1dc8cd; }

.label-success {
  background-color:#2ea033; }

.label-info {
  background-color: #da0b4e; }

.label-warning {
  background-color: #ff9b20; }

.label-danger {
  background-color: #f33066; }

.label-megna {
  background-color: #1dc8cd; }

.label-primary {
  background-color: #da0b4e; }

.label-purple {
  background-color: #7460ee; }

.label-red {
  background-color: #fb3a3a; }

.label-inverse {
  background-color: #3e4555; }

.label-default {
  background-color: #f4f8fa; }

.label-white {
  background-color: #ffffff; }

.label-light-success {
  background-color: #e8fdeb;
  color: #da0b4e; }

.label-light-info {
  background-color: #cfecfe;
  color: #da0b4e; }

.label-light-warning {
  background-color: #fff8ec;
  color: #ff9b20; }

.label-light-danger {
  background-color: #f9e7eb;
  color: #f33066; }

.label-light-megna {
  background-color: #e0f2f4;
  color: #1dc8cd; }

.label-light-primary {
  background-color: #f1effd;
  color: #da0b4e; }

.label-light-inverse {
  background-color: #f6f6f6;
  color: #3e4555; }

/*------------ GLobal Settings: Table ------------*/
.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid transparent;
    border-top: 0px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color:#f7f9fb;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border-top: 1px solid #f7f9fb;
}
.table tr th, .table tr td {
    border-color: #eaeff5;
    padding: 12px 15px;
    vertical-align: middle;
}
.table.tbl-big tr th, .table.tbl-big tr td {
    padding:20px 15px;
}
.table.tbl-big.center tr th, .table.tbl-big.center tr td {
    padding:20px 15px;
	text-align:center;
}
table.table tr th {
    font-weight: 600;
}
.table-dark {
    color: #fff;
    background-color: #212529;
}
.table-dark td, .table-dark th, .table-dark thead th {
    border-color: #32383e !important;
}
.table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
}

/*----------- Group Button ------------*/
.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group .btn-secondary a{
	box-shadow:none !important;
    border-left: 1px solid rgba(255,255,255,0.1) !important;
}
.btn-group .btn-secondary:first-child {
    border-left:none !important;
}
.btn-group .btn {
    border-radius: 0.3rem;
	overflow: hidden;
	padding:0px;
}
.btn-group .btn a {
	padding:1rem 1.5rem;
}
.nav-link.active, .btn-group .btn-secondary:hover a,.btn-group .btn-secondary:focus a, .btn-group .btn-secondary .nav-link {
    color: #ffffff;
}
label.btn {
    border: none !important;
}
.btn-group .btn-secondary a {
    color: #fff !important;
    background-color: #122947 !important;
    border-radius: 0 !important;
}
.btn-group .btn-secondary .nav-link.active, .btn-group .btn-secondary .nav-link:hover, .btn-group .btn-secondary .nav-link:focus {
    color:#fff;
    background-color:#D70040 !important;
}

/*------------- GLobal Settings: Badge ----------------*/
.badge {
  font-weight: 400; }

.badge-xs {
  font-size: 9px; }

.badge-xs,
.badge-sm {
  -webkit-transform: translate(0, -2px);
  -ms-transform: translate(0, -2px);
  -o-transform: translate(0, -2px);
  transform: translate(0, -2px); }

.badge-success {
  background-color:#2ea033; }

.badge-info {
  background-color: #da0b4e; }

.badge-primary {
  background-color: #da0b4e; }

.badge-warning {
  background-color: #ff9b20; }

.badge-danger {
  background-color: #f33066; }

.badge-purple {
  background-color: #7460ee; }

.badge-red {
  background-color: #fb3a3a; }

.badge-inverse {
  background-color: #3e4555; }


/*------------- GLobal Settings: List-style-none ----------------*/
ul.list-style-none {
	margin: 0px;
	padding: 0px;
}

ul.list-style-none li {
	list-style: none; 
}

ul.list-style-none li a {
	color: #8d97ad;
	padding: 8px 0px;
	display: block;
	text-decoration: none;
	font-size: 15.5px;
}
ul.list-style-none li a:hover {
	color: #da0b4e;
}


/*------------- GLobal Settings: dropdown ----------------*/
.dropdown-item {
  padding: 8px 1rem;
  color:#607394;
  font-size: 15.5px;
}


/*------------- GLobal Settings: Buttons ----------------*/
.btn {
    border: 2px solid transparent;
}
.btn {
	color: #ffffff;
	padding: 12px 25px;
	cursor: pointer;
	-webkit-transition: 0s;
	-o-transition: 0s;
	transition: 0s;
	border-radius:0.1rem;
}
a.btn-trans-video{
    font-size: 1em;
    padding: 0.8em 1.6em;
	color: #da0b4e;
} 
a.btn-trans-video i {
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    background: #da0b4e;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    margin-right: 20px;
    box-shadow: 0px 0px 0px 12px rgb(179, 220, 255);
	-webkit-box-shadow: 0px 0px 0px 12px rgb(179, 220, 255);
}
.light-vid  a.btn-trans-video{
	color:#ffffff;
} 
.light-vid a.btn-trans-video i {
    background: #3583ff;
    color: #ffffff;
    box-shadow:0px 0px 0px 7px rgba(255,255,255,0.5);
	-webkit-box-shadow:0px 0px 0px 7px rgba(255,255,255,0.5);
}
.btn:hover {
	color: #ffffff;
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #263238;
  padding: 10px 15px; }
  
.btn-link .underline {
    border-bottom: 1px solid #263238;
}
	
.btn-link:hover {
    color: #da0b4e;
}

.btn-light {
  color: #263238;
  border:1px solid #ffffff;
  border-radius:4px;
  margin-bottom:5px;
}
  
.btn-light:hover {
    background:#252c41;
    color: #ffffff !important;
    border-color:#252c41;
}

.btn-lg {
    padding: 0.8em 2em;
	border-radius:0.4rem;
    font-size: 1.2em;
}

.btn-md {
  padding:1em 1.5em;
  font-size:1em;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: 12px;
}

.btn-xs {
  padding: .25rem .5rem;
  font-size: 10px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}

.btn-circle.btn-md {
    padding: 18px 0px;
    width: 60px;
    height: 60px;
    font-size: 20px;
}

.btn-circle.btn-sm {
  width: 35px;
  height: 35px;
  padding: 8px 10px;
  font-size: 14px;
}

.btn-circle.btn-lg {
  width: 70px;
  height: 70px;
  padding: 24px 15px;
  font-size: 20px;
  line-height: 23px;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 14px 15px;
  font-size: 24px;
}

.btn-rounded {
  border-radius:50px !important;
  -webkit-border-radius:50px !important;
}

.btn-arrow {
  position: relative;
}
  
.btn-arrow span {
    display: inline-block;
    position: relative;
    -webkit-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    will-change: transform;
}
	
.btn-arrow:hover span, .btn-arrow:focus span {
    -webkit-transform: translate3d(-1rem, 0, 0);
    transform: translate3d(-1rem, 0, 0);
}
	
.btn-arrow i {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    will-change: right, opacity;
}
	
.btn-arrow:hover i, .btn-arrow:focus i {
    opacity: 1;
    right: -2rem; 
}
.btn-theme{
	background:#da0b4e;
	border-color:#da0b4e;
	color:#ffffff;
	transition:all ease 0.4s;
}
.btn-theme-2{
	background:#3151B7;
	border-color:#3151B7;
	color:#ffffff;
	transition:all ease 0.4s;
}
.btn-theme-2:hover, .btn-theme-2:focus{
	background:#da0b4e;
	border-color:#da0b4e;
	color:#ffffff !important;
}
.btn-outline-theme {
    color: #da0b4e;
    background-color: transparent;
    border:2px solid #da0b4e;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}
.btn-outline-theme:hover, .btn-outline-theme:focus {
    color:#ffffff !important;
    background-color:#da0b4e;
    border-color:#da0b4e;
}
.btn-outline-theme-2 {
    color:#3151B7;
    background-color: transparent;
    border:2px solid #3151B7;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}
.btn-outline-theme-2:hover, .btn-outline-theme-2:focus {
    color:#ffffff;
    background-color:#3151B7;
    border-color:#3151B7;
}
.btn-secondary,
.btn-secondary.disabled {
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  background-color:#eef0f3;
  color: #8d97ad;
}
  
.btn-secondary:hover,
.btn-secondary.disabled:hover {
    color: #ffffff !important;
}
	
.btn-secondary.active, .btn-secondary:active, .btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
    color: #ffffff !important;
    background: #263238;
    border-color: #263238;
}

.btn-primary,
.btn-primary.disabled {
  background:#da0b4e;
  border: 1px solid #da0b4e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; 
}
  
.btn-primary:hover, .btn-primary.disabled:hover {
    background: #e05707;
    border: 1px solid #e05707;
    color: #ffffff !important;
}
	
.btn-primary.active, .btn-primary:active, .btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
    background: #345bcb;
}

.btn-themecolor,
.btn-themecolor.disabled {
  background: #da0b4e;
  color: #ffffff;
  border: 1px solid #da0b4e;
}
  
.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
    background: #028ee1;
    border: 1px solid #028ee1;
}
.btn-black{
  background:#263547;
  color: #ffffff;
  border:1px solid #263547;
}
  
.btn-black:hover,
.btn-black:focus {
    background:#0f7dff;
    border: 1px solid #0f7dff;
}
	
.btn-themecolor.active, .btn-themecolor:active, .btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:active,
.btn-themecolor.disabled:focus {
    background: #028ee1;
}

.btn-success,
.btn-success.disabled {
  background:#2ea033;
  border: 1px solid #2ea033;
  color: #ffffff;
}
  
.btn-success:hover,
.btn-success.disabled:hover {
    background: #1fd628;
    border: 1px solid #1fd628;
}
	
.btn-success.active, .btn-success:active, .btn-success:focus,
.btn-success.disabled.active,
.btn-success.disabled:active,
.btn-success.disabled:focus {
    background: #1fd628;
}

.btn-info,
.btn-info.disabled {
  background:#1ac790;
  border: 1px solid #1ac790;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
.btn-info:hover,
.btn-info.disabled:hover {
    background:#1ac790;
    border: 1px solid #1ac790; 
}
	
.btn-info.active, .btn-info:active, .btn-info:focus,
.btn-info.disabled.active,
.btn-info.disabled:active,
.btn-info.disabled:focus {
    background: #0976ea;
}

.btn-warning,
.btn-warning.disabled {
  background: #ff9b20;
  color: #ffffff;
  border: 1px solid #ff9b20;
}
  
.btn-warning:hover,
.btn-warning.disabled:hover {
    background: #f1901a;
    color: #ffffff;
    border: 1px solid #f1901a;
}
	
.btn-warning.active, .btn-warning:active, .btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
    background: #f1901a;
    color: #ffffff;
}

.btn-danger,
.btn-danger.disabled {
  background: #f33066;
  border: 1px solid #f33066;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
.btn-danger:hover,
.btn-danger.disabled:hover {
    background: #d73e6b;
    border: 1px solid #d73e6b;
}
	
.btn-danger.active, .btn-danger:active, .btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
    background: #d73e6b;
}

.btn-inverse,
.btn-inverse.disabled {
  background: #3e4555;
  border: 1px solid #3e4555;
  color: #ffffff;
}
  
.btn-inverse:hover,
.btn-inverse.disabled:hover {
    background: #232a37;
    color: #ffffff;
    border: 1px solid #232a37;
}

.btn-inverse.active, .btn-inverse:active, .btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
    background: #232a37;
    color: #ffffff;
}

.btn-red,
.btn-red.disabled {
  background: #fb3a3a;
  border: 1px solid #fb3a3a;
  color: #ffffff;
}
  
.btn-red:hover,
.btn-red.disabled:hover {
    border: 1px solid #d61f1f;
    background: #d61f1f;
}
	
.btn-red.active, .btn-red:active, .btn-red:focus,
  .btn-red.disabled.active,
  .btn-red.disabled:active,
  .btn-red.disabled:focus {
    background: #d73e6b;
}
.btn-outline-secondary {
  background-color: #ffffff;
  color: #727b84;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
.btn-outline-secondary.active, .btn-outline-secondary:active, .btn-outline-secondary:focus {
    background: #263238;
}

.btn-outline-primary {
  color: #da0b4e;
  background-color: #ffffff;
  border-color: #da0b4e;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
    background: #da0b4e;
    color: #ffffff;
    border-color: #da0b4e; }
.btn-outline-primary.active, .btn-outline-primary:active, .btn-outline-primary:focus {
    background: #345bcb;
}

.btn-outline-success, a.btn-outline-success {
  color:#2ea033;
  background-color: transparent;
  border-color: #2ea033;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success.focus, a.btn-outline-success:hover, a.btn-outline-success:focus, a.btn-outline-success.focus {
    background:#2ea033;
    border-color: #2ea033;
    color: #ffffff;
}
	
.btn-outline-success.active, .btn-outline-success:active, .btn-outline-success:focus, a.btn-outline-success.active, a.btn-outline-success:active, a.btn-outline-success:focus {
    background: #1fd628;
}

.btn-outline-info {
  color:#1ac790;
  background-color: transparent;
  border-color:#1ac790;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info.focus {
    background:#1ac790;
    border-color:#1ac790;
    color: #ffffff;
}
	
.btn-outline-info.active, .btn-outline-info:active, .btn-outline-info:focus {
    background:#1ac790;
}

.btn-outline-warning {
  color: #ff9b20;
  background-color: transparent;
  border-color: #ff9b20;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in; 
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning.focus {
    background: #ff9b20;
    border-color: #ff9b20;
    color: #ffffff;
}
	
  .btn-outline-warning.active, .btn-outline-warning:active, .btn-outline-warning:focus {
    background: #f1901a;
}

.btn-outline-danger {
  color: #f33066;
  background-color: transparent;
  border-color: #f33066;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}
  
  .btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger.focus {
    background: #f33066;
    border-color: #f33066;
    color: #ffffff;
}
  .btn-outline-danger.active, .btn-outline-danger:active, .btn-outline-danger:focus {
    background: #d73e6b; }

.btn-outline-red {
  color: #fb3a3a;
  background-color: transparent;
  border-color: #fb3a3a; }
  
  .btn-outline-red:hover, .btn-outline-red:focus, .btn-outline-red.focus {
    background: #fb3a3a;
    border-color: #fb3a3a;
    color: #ffffff;
}
	
  .btn-outline-red.active, .btn-outline-red:active, .btn-outline-red:focus {
    background: #d73e6b;
}

.btn-outline-inverse {
  color: #3e4555;
  background-color: transparent;
  border-color: #3e4555;
}

.btn-outline-inverse:hover, .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    background: #3e4555;
    border-color: #3e4555;
    color: #ffffff;
}

.btn-outline-light:hover {
  color: #263238 !important;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: #345bcb;
  border: 1px solid #345bcb;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  background-color: #1fd628;
  border: 1px solid #1fd628;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover,
.btn-info.focus,
.btn-info:focus {
  background-color:#1ac790;
  border: 1px solid #1ac790;
  color: #ffffff;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus {
  background-color: #f1901a;
  border: 1px solid #f1901a; 
  color: #ffffff;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus {
  background-color: #d73e6b;
  border: 1px solid #d73e6b;
  color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open > .dropdown-toggle.btn-inverse {
  background-color: #232a37;
  border: 1px solid #232a37;
  color: #ffffff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open > .dropdown-toggle.btn-red {
  background-color: #d61f1f;
  border: 1px solid #d61f1f;
  color: #ffffff;
}

.btn span.btn-devider {
  display: inline-block;
  padding-left: 10px;
}
.btn-warning-light{
	color: #ff8f00!important;
    background: rgba(255,143,0,0.1);
    border-color: rgba(0,0,0,0);
}
.btn-warning-light:hover, .btn-warning-light:focus{
	color:#ffffff !important;
    background:#ff8f00;
    border-color:#ff8f00;
}
.btn-success-light{
	color:#29cf8d !important;
    background: rgba(41,207,141,0.1);
    border-color: rgba(0,0,0,0);
}
.btn-success-light:hover, .btn-success-light:focus{
	color:#ffffff !important;
    background:#29cf8d;
    border-color:#29cf8d;
}
.btn-primary-light{
	color:#da0b4e !important;
    background: rgba(51,137,255,0.1);
    border-color: rgba(0,0,0,0);
}
.btn-primary-light:hover, .btn-primary-light:focus{
	color:#ffffff !important;
    background:#da0b4e;
    border-color:#da0b4e;
}
.btn-danger-light{
	color:#ed2929 !important;
    background: rgba(237,41,41,0.1);
    border-color: rgba(0,0,0,0);
}
.btn-danger-light:hover, .btn-danger-light:focus{
	color:#ffffff !important;
    background:#ed2929;
    border-color:#ed2929;
}
.btn-over-light{
	color:#ffffff !important;
    background: rgba(255,255,255,0.1);
    border-color: rgba(255,255,255,0.2);
}
.btn-over-light:hover, .btn-over-light:focus{
	color:#ffffff !important;
    background:#ff8f00;
    border-color: rgba(255,255,255,0.2);
}
/*----------- GLobal Settings: Video ------------------*/
.cover .video-block:not(:first-child):not(:last-child) {
    margin: 2.77em 0;
}
.video-play-icon {
    width:6.42857143em;
    height:6.42857143em;
    border-radius: 50%;
    position: relative;
    z-index:2;
    display: inline-block;
    border: 2px solid #ffffff;
    cursor: pointer;
	line-height:6em;
    background: #ffffff;
	box-shadow:0px 0px 0px 15px rgba(255,255,255,0.2);
	-webkit-box-shadow:0px 0px 0px 15px rgba(255,255,255,0.2);
}
.video-play-icon a {
    font-size: 3em;
	color:#da0b4e;
}
/*----------- GLobal Settings: Pagination ------------------*/
.pagination {
    display: table;
    padding-left: 0;
    border-radius: 4px;
    margin:20px auto 0 !important;
}

.pagination>li>a,
.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 0;
    margin:5px;
    color: #5a6f7c;
    text-decoration: none;
    background-color: #fff;
    border-radius: 50%;
    width:40px;
    height:40px;
    text-align: center;
    line-height:40px;
    border: 1px solid #eaeff5;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover,
.pagination>li>a:focus,
.pagination>li>a:hover {
    z-index: 2;
    color:#ffffff;
    cursor: pointer;
    background-color:#da0b4e;
    border-color:#da0b4e;
}

.pagination li:first-child a {
    background:#da0b4e;
    border: 1px solid #da0b4e;
    border-radius:50% !important;
    color: #fff
}

.pagination li:last-child a {
    background: #35434e;
    border: 1px solid #35434e;
    border-radius:50% !important;
    color: #fff
}
.pagination>li {
    display: inline;
}
.page-item.active .page-link {
    z-index: 2;
    color: #fff;
    background-color: #da0b4e;
    border-color: #da0b4e;
}
/*----------- Global Settings: Custom Checkbox & Radio Button ----------------*/
.checkbox-custom, .radio-custom {
opacity: 0;
position: absolute;   
}

.checkbox-custom, .checkbox-custom-label, .radio-custom, .radio-custom-label {
display: inline-block;
vertical-align: middle;
margin:5px 0;
cursor: pointerer;
}
.checkbox-custom-label, .radio-custom-label {
position: relative;
}
.checkbox-custom + .checkbox-custom-label:before{
content: '';
background: #fff;
border: 2px solid #d8dfe6;
display: inline-block;
vertical-align: middle;
width: 20px;
height: 20px;
border-radius:3px;
padding: 2px;
margin-right: 10px;
text-align: center;
}

.radio-custom + .radio-custom-label:before {
content: '';
background: #fff;
border: 2px solid #d8dfe6;
display: inline-block;
vertical-align: middle;
width: 20px;
height: 20px;
border-radius: 50%;
padding: 2px;
margin-right: 10px;
text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
background: #da0b4e;
border-radius:2px;
box-shadow: inset 0px 0px 0px 4px #fff;
}

.radio-custom + .radio-custom-label:before {
border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
background:#da0b4e;
box-shadow: inset 0px 0px 0px 4px #fff;
}
.company-brands label{
font-weight:normal;
color:#828f99;
font-size:15px;
}

.checkbox-custom:focus + .checkbox-custom-label, .radio-custom:focus + .radio-custom-label {
  outline:none /* focus style */
}
.company-brands input[type='checkbox']:focus{
outline:none;
}
.company-brands label:focus{
outline:none;
}

/*--------------- GLobal Settings: On off switch ---------------*/
.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0 auto;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "";
    padding-right: 27px;
    background-color: #da0b4e;
    color: #FFFFFF;
}
  .onoffswitch-inner:after {
    content: "";
    padding-right: 24px;
    background-color: #3e4555;
    color: #999999;
    text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 23px;
  margin: 6px;
  background: #FFFFFF;
  height: 23px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 35px;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
/*----------- GLobal Settings: Custom Radio Button ------------*/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #da0b4e;
    position: absolute;
    top:3px;
    left:3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
/*------------- GLobal Settings: Bootstrap 4 hack --------------*/
.card-columns {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.card-columns .card {
    margin-bottom: 30px;
}

.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
	.hidden-xs-down {
		display: none !important;
	}
}

@media (min-width: 576px) {
	.hidden-sm-up {
		display: none !important;
	}
}

.hidden-xl-down {
  display: none !important;
}

/*---------- GLobal Settings: Section Heading Style ---------*/
.sec-heading {
	margin-bottom:2rem;
}
.sec-heading.lg {
	margin-bottom:30px;
}
.sec-heading.center {
	text-align:center;
}
.sec-heading h2, .sec-heading p{
	margin-bottom:5px;
}
.sec-heading.light h2, .sec-heading.light p{
	color:#ffffff;
}
.color-heading .sec-heading h2{
    color: #D70040;	
}
.sec-heading p {
    margin-bottom: 0;
    line-height: 1.2;
}
.sec-heading-flex{
	display:flex;
	width:100%;
	margin-bottom:1.5rem;
	align-items:center;
	position:relative;
}
.sec-heading-flex-one{
	flex:1;
}
.sec-heading-flex-one h2{
	margin-bottom:0;
	font-size:22px;
	font-family:'Playfair Display', serif;
}
.sec-heading h2{
	font-family:'Playfair Display', serif;
}
.f_2{
	font-family:'Playfair Display', serif;
}
.single-items {
    padding: 0 10px;
}
.property-slide, .item-slide{
    margin: 0 -15px;
}
.sec-heading2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.sec-left {
    flex: 1;
}
.sec-left h3 {
    font-size: 20px;
    margin-bottom: 0px;
}
.sec-right a {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
}
/*------------ GLobal Settings: All Forms Style ---------------*/
.frm_submit_block {
    margin-bottom: 30px;
}
.form-control {
    height:54px;
    border-radius:4px;
    font-size:15px;
    box-shadow: none;
	padding:.5rem .75rem;
    border:1px solid #e6eaf3;
	background-clip: initial;
}
.submit-form .form-control, .submit-page .form-control, .frm_submit_block .form-control{
    background: #f9fafc;
    border: 1px solid #ebedf5;
    border-radius: 3px;
	color: #646e77;
}
.form-row>.col, .form-row>[class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
}
.frm_submit_block h3 {
    margin-left: 10px;
}
.form-control.simple {
    background: #f2f3f5;
    border-color: #f2f3f5;
    border-radius: 4px;
}
.select2-container {
    width: 100% !important;
    z-index: 55;
}
span.select2-selection.select2-selection--single {
    padding-left: 15px;
    border-radius: 4px;
    border: 1px solid #e6eaf3;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-radius: 0;
	border:none;
    height:54px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #16447d;
    font-weight:500;
    line-height:54px;
    text-align: left;
    padding:0 15px 0 0;
	width: 100%;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height:50px;
    position: absolute;
    top: 1px;
    right: 10px;
    width: 20px;
}
.full_search_box.modern .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 29px;
}
.full_search_box.modern .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height:29px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color:#647b9c transparent transparent transparent;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color:#647b9c;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    right: 14px;
    top:1px;
}
.image-bg .form-control, .bg--dark .form-control {
	color:#495057 !important;
}
select.form-control:not([size]):not([multiple]) {
    height: 56px;
}
select.form-control.fc-lg:not([size]):not([multiple]) {
    height:62px;
}
.form-control.fc-lg {
    height:62px;
}
textarea.form-control{
	height:140px;
}
textarea.form-control.big{
	height:180px;
}
.form-control::-webkit-input-placeholder{color:#647b9c;font-weight:500;}
.form-control:-ms-input-placeholder{color:#647b9c;font-weight:500;}
.form-control::placeholder{color:#647b9c;font-weight:500;}

/*------------- Form --------------*/
.tip-topdata {
    position: relative;
    color: #fff;
    text-decoration: none;
    width: 15px;
    height: 15px;
    display: inline-block;
    background: #394d8c;
    border-radius: 50%;
    text-align: center;
    line-height: 19px;
    margin-left: 5px;
    font-size: 9px;
}
.tip-topdata:before {
    content: attr(data-tip);
    font-size: 11px;
    font-weight: bold;
    position: absolute;
    z-index: 999;
    white-space: nowrap;
    bottom: 9999px;
    left: 0;
    background:#394d8c;
    color: #ffffff;
    padding: 7px 15px;
    line-height: 25px;
    border-radius: 3px;
    top: -42px;
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    -o-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out;
    text-shadow: none;
    display: inline-table;
}

.tip-topdata:hover:before {
opacity: 1;
}
.frm_submit_block .select2-container--default .select2-selection--single {
    background-color: #f9fafc;
    border-radius: 0;
    border: none;
    height: 56px;
    border: 1px solid #ebedf5;
    border-radius: 4px;
    padding-left: 15px;
}
.frm_submit_block .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 55px;
}

/*---------- Input Group -----------*/
.input-group .form-control {
    border-radius: 4px;
}
footer.style-2 {
    padding-top: 0;
}
.top-footer {
    padding:3rem 0;
}
.foot-news-first h5 {
    margin: 0;
}
.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}
.input-group-append, .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-append {
    margin-left: -1px;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.top-footer .input-group-text {
    background: transparent;
    border: none;
    cursor: pointer;
}
.top-footer input.form-control {
    border: none;
    background: transparent !important;
    min-width: 270px;
}
.top-footer .input-group {
    background: transparent;
    border-radius: 4px;
}
.foot-news-last .input-group-text {
    color: #ffffff;
}
.top-footer .input-group {
    background: transparent;
    border-radius: 0px;
    border-bottom: 1px solid rgba(255, 255, 255,0.2);
}
.top-footer .input-group .form-control{
	color:#ffffff;
}
.top-footer .form-control::-webkit-input-placeholder{color:rgba(255,255,255,0.7);font-weight:500;}
.top-footer .form-control:-ms-input-placeholder{color:rgba(255,255,255,0.7);font-weight:500;}
.top-footer .form-control::placeholder{color:rgba(255,255,255,0.7);font-weight:500;}
.dark-footer .top-footer {
    border-bottom: 1px solid #333c56;
}
.dark-footer .input-group {
    background: #2c344c;
}
.dark-footer .input-group-text {
    color:#7482ad;
}
.dark-footer .top-footer input.form-control {
    color:#ffffff;
}
.dark-footer .foot-news-first h5 {
    color: #ffffff;
}
/**************************************
	Header Navigation Styles
***************************************/
/*---------- Alert Top ---------------*/
.alert.alert_top {
    background: #003783;
    padding: .55rem 1.25rem;
	margin:0;
    font-size: 14px;
    border-radius: 0;
}
.alert_caption {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.alert_rate {
    display: inline-block;
	margin-right: 10px;
    font-size: 9px;
    color: #ffffff;
}
.alert_rate i.filled {
    color: #FF9800;
}
.alert_rate i{
   margin-right:2px;
}
.alert_caption {
    color:#5ab2fe;
}
.alert_caption a {
    color: #ffffff;
}

.alert_caption p {
    margin: 0;
    display: inline-block;
}
.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 15px;
    padding: .65rem 1.25rem;
    color: #ffffff;
    cursor: pointer;
    outline: none;
}
/*--------- General Features: Topbar -----------*/
.topbar-head{
    padding:12px 0px;
    background:#13213a;
}
.topbar-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.topbar-head ul.tp-list {
    display: inline-block;
    margin-right: 0;
	margin:0;
	padding: 0;
}
.topbar-head ul.tp-list.nbr {
    border-right:none;
}
.topbar-head ul li{
    display:inline-block;
	list-style:none;
	padding-right: 18px;
}
.topbar-head ul ul.dropdown-menu.mlix-wrap li a img {
    display: inline-block;
    max-width: 15px;
	margin-right:5px;
}
.topbar-head ul li:last-child{
	padding-left:0px;
}
.topbar-head ul li a {
    color: #FFF;
    font-weight: 400;
    font-size: 12px;
}
ul.dropdown-menu.mlix-wrap {
    background: #13213a;
    max-width:120px !important;
    min-width: 60px;
    padding: 1rem;
    right: 0;
    left: auto !important;
}
.topbar-head ul ul.dropdown-menu.mlix-wrap li {
	padding-right:0;
	width: 100%;
}
ul.tp-nav li {
    margin-bottom: 10px;
    display: block;
    transition: all ease 0.4s;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfe3ea;
}
ul.tp-nav li:hover, ul.tp-nav li:focus{
    margin-left:10px;
}
.tp-side-menu {
    padding: 5px 10px;
    background: #1a2b4a;
    border-radius: 2px;
    color: #859cc5 !important;
}
/*----------- Navigation -------------*/
.nav-brand,
.nav-brand:focus,
.nav-brand:hover,
.nav-menu>li>a {
    color:#404656;
}

.menu__list,
.nav-dropdown,
.nav-menu,
ul {
    list-style: none
}

.menu__link,
.navigation,
.navigation * {
    -webkit-tap-highlight-color: transparent
}

.navigation,
.navigation * {
    box-sizing: border-box
}

.navigation {
    width: 100%;
    height: 70px;
    display: table;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.nav-toggle,
.navigation-hidden .nav-header {
    display: none
}

.navigation-portrait {
    height: 48px
}

.navigation-fixed {
    position: fixed;
    top: 0;
    left: 0
}

.navigation-hidden {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important
}

.align-to-right {
    float: right;
    letter-spacing: 0.1px;
}

.nav-header {
    float: left
}

.nav-brand {
    padding:17px 15px;
    font-size: 24px;
	margin-right: 1rem;
	padding-left: 0;
    text-decoration: none !important
}

.deatils ul li a,
.indicate a,
.logo h1 a,
.nav-button,
.nav-dropdown>li>a,
.nav-menu>li>a,
nav a {
    text-decoration: none
}

.navigation-portrait .nav-brand {
    font-size: 18px;
    line-height: 48px
}

.nav-logo>img {
    height: 48px;
    margin: 11px auto;
    padding: 0 15px;
    float: left
}

.nav-logo:focus>img {
    outline: initial
}

.deatils ul li a,
.indicate a,
.menu__link:focus,
.menu__link:hover,
nav a,
nav a:focus,
nav a:hover {
    outline: 0
}

.navigation-portrait .nav-logo>img {
    height: 36px;
    margin: 6px auto 6px 15px;
    padding: 0
}

.nav-toggle {
    width: 30px;
    height: 30px;
    padding: 6px 2px 0;
    position: absolute;
    top: 50%;
    margin-top: -14px;
    right: 15px;
    cursor: pointer
}

.nav-toggle:before {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #70798b;
    border-radius: 10px;
    box-shadow: 0 .5em 0 0 #70798b, 0 1em 0 0 #70798b
}

.navigation-portrait .nav-toggle {
    display: block
}

.navigation-portrait .nav-menus-wrapper {
    width: 320px;
    height: 100%;
    top: 0;
    left: -400px;
    position: fixed;
    background-color:#1c2138;
    z-index: 20000;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition-duration: .8s;
    transition-timing-function: ease
}

.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
    left: auto;
    right: -400px
}

.nav-menus-wrapper.nav-menus-wrapper-open {
    left: 0
}

.nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
    right: 0
}

.nav-menus-wrapper-close-button {
    width: 30px;
    height: 40px;
    margin: 10px 7px;
    display: none;
    float: right;
    color: #70798b;
    font-size: 26px;
    cursor: pointer
}

.navigation-portrait .nav-menus-wrapper-close-button {
    display: block
}

.nav-menu {
    margin: 0;
    padding: 0;
    line-height: normal
}

.navigation-portrait .nav-menu {
    width: 100%
}

.navigation-landscape .nav-menu.nav-menu-centered {
    float: none;
    text-align: center
}

.nav-menu>li {
    display: inline-block;
    float: left;
    text-align: left
}

.navigation-portrait .nav-menu>li {
    width: 100%;
    position: relative;
    border-top: solid 1px #2d3454;
}

.navigation-portrait .nav-menu>li:last-child {
    border-bottom: solid 1px #2d3454
}

.nav-menu+.nav-menu>li:first-child {
    border-top: none
}

.navigation-landscape .nav-menu.nav-menu-centered>li {
    float: none
}

.nav-menu>li>a {
    padding:30px 12px;
    display: inline-block;
    transition: color .3s, background .3s;
}

.navigation-portrait .nav-menu>li>a {
    height: auto;
    width: 100%;
	color: #787fa0;
    padding: 12px 15px 12px 26px
}

.nav-menu>.active>a,
.nav-menu>.focus>a,
.nav-menu>li:hover>a {
    color: #D70040 !important;
}

.nav-menu>li>a>[class*=ion-], .nav-menu>li>a>i {
    width: 18px;
    height: 16px;
    line-height: 16px;
    -ms-transform: scale(1.4);
    /* transform: scale(1.4); */
    top: 1px;
    position: relative;
}

.nav-menu>li>a>[class*=ion-] {
    width: 16px;
    display: inline-block;
    transform: scale(1.8)
}

.navigation-portrait .nav-menu.nav-menu-social {
    width: 100%;
    text-align: center
}

.nav-menu.nav-menu-social>li {
    text-align: center;

    border: none !important
}
.nav-menu+.nav-menu>li:first-child {
    position: relative;
}
.nav-menu+.nav-menu.dhsbrd>li:first-child:before {
    display:none;
}
.navigation-portrait .nav-menu.nav-menu-social>li {
    width: auto
}

.nav-menu.nav-menu-social>li>a>[class*=ion-] {
    font-size: 12px
}

.nav-menu.nav-menu-social>li>a>.fa, .nav-menu.nav-menu-social>li>a>.fas {
    font-size: 15px
}

.navigation-portrait .nav-menu.nav-menu-social>li>a {
    padding: 15px
}

.submenu-indicator {
    margin-left: 6px;
    margin-top: 6px;
    float: right;
    transition: all .3s
}

.navigation-portrait .submenu-indicator {
    width: 54px;
    height: 44px;
    margin-top: 0;
    position: absolute;
    text-align: center;
    z-index: 20000
}

.submenu-indicator-chevron {
    height: 6px;
    width: 6px;
    display: block;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: transparent #70798b #70798b transparent;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: border .3s
}

.navigation-portrait .submenu-indicator-chevron {
    position: absolute;
    top: 18px;
    left: 24px
}

.nav-menu>.active>a .submenu-indicator-chevron,
.nav-menu>.focus>a .submenu-indicator-chevron,
.nav-menu>li:hover>a .submenu-indicator-chevron {
    border-color: transparent #D70040 #D70040 transparent
}

.navigation-portrait .submenu-indicator.submenu-indicator-up {
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg)
}

.nav-overlay-panel {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: none;
    z-index: 19999
}

.no-scroll {
    width: 100%;
    height: 100%;
    overflow: hidden
}

.nav-search {
    height: 70px;
    float: right;
    z-index: 19998
}

.navigation-portrait .nav-search {
    height: 48px;
    padding: 0 10px;
    margin-right: 52px
}

.navigation-hidden .nav-search {
    display: none
}

.nav-search-button {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
    background-color: #fbfcfd
}

.nav-search-icon {
    width: 14px;
    height: 14px;
    margin: 2px 8px 8px 4px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    color: #70798b;
    text-align: left;
    text-indent: -9999px;
    border: 2px solid;
    border-radius: 50%;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    transition: color .3s
}

.nav-search-icon:after,
.nav-search-icon:before {
    content: '';
    pointer-events: none
}

.nav-search-icon:before {
    width: 2px;
    height: 11px;
    top: 11px;
    position: absolute;
    left: 50%;
    border-radius: 0 0 1px 1px;
    box-shadow: inset 0 0 0 32px;
    transform: translateX(-50%)
}

.nav-search-button:hover .nav-search-icon {
    color: #D70040
}

.navigation-portrait .nav-search-button {
    width: 50px;
    height: 48px;
    line-height: 46px;
    font-size: 22px
}

.nav-search>form {
    width: 100%;
    height: 100%;
    padding: 0 auto;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 99
}

.nav-search-inner {
    width: 70%;
    height: 70px;
    margin: auto;
    display: table
}

.navigation-portrait .nav-search-inner {
    height: 48px
}

.nav-search-inner input[type=search],
.nav-search-inner input[type=text] {
    height: 70px;
    width: 100%;
    margin: 0;
    padding: 0 12px;
    font-size: 26px;
    text-align: center;
    color: #70798b;
    outline: 0;
    line-height: 70px;
    border: none;
    background-color: transparent;
    transition: all .3s
}

.navigation-portrait .nav-search-inner input[type=search],
.navigation-portrait .nav-search-inner input[type=text] {
    height: 48px;
    font-size: 18px;
    line-height: 48px
}

.nav-search-close-button {
    width: 28px;
    height: 28px;
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    line-height: normal;
    color: #70798b;
    font-size: 20px;
    cursor: pointer;
    text-align: center
}

.nav-button,
.nav-text {
    display: inline-block;
    font-size: 14px
}

.navigation-portrait .nav-search-close-button {
    top: 10px;
    right: 14px
}

.nav-button {
    margin: 18px 15px 0;
    padding: 8px 14px;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    background-color: #D70040;
    transition: opacity .3s
}

.nav-button:focus,
.nav-button:hover {
    color: #fff;
    text-decoration: none;
    opacity: .85
}

.navigation-portrait .nav-button {
    width: calc(100% - 52px);
    margin: 17px 26px
}

.nav-text {
    margin: 25px 15px;
    color: #70798b
}

.navigation-portrait .nav-text {
    width: calc(100% - 52px);
    margin: 12px 26px 0
}

.navigation-portrait .nav-text+ul {
    margin-top: 15px
}

.nav-dropdown {
    min-width:250px;
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    z-index: 98;
    white-space: nowrap
}

.navigation-portrait .nav-dropdown {
    width: 100%;
    position: static;
    left: 0
}

.nav-dropdown .nav-dropdown {
    left: 100%
}

.nav-menu>li .nav-dropdown {
    border: none;
	padding:15px;
	background:#ffffff;
	border-radius: 0.5rem;
    box-shadow: 0px 0px 42px 0px rgba(32, 32, 32, 0.15);
	-webkit-box-shadow: 0px 0px 42px 0px rgba(32, 32, 32, 0.15);    
}
.nav-menu>li>.nav-dropdown:before {
  background-color: #ffffff;
  content: "";
  width:22px;
  height:22px;
  left:27px;
  top:-12px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s; 
}
.nav-dropdown>li {
    width: 100%;
    float: left;
    clear: both;
    position: relative;
    text-align: left
}

.nav-dropdown>li>a {
    width: 100%;
    padding:14px 20px 14px 10px;
	border-bottom: 1px solid #e4e8ec;
    display: inline-block;
    float: left;
    color: #70798b;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.nav-dropdown>li:last-child>a{
	border-bottom:none;
}
.nav-dropdown>li>a:hover, .nav-dropdown>li>a:focus {
    padding-left: 20px;
	color: #D70040;
}
.social-icon a i,
.social-icons a i {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 16px
}

.nav-dropdown>.focus>a,
.nav-dropdown>li:hover>a {
    color:red;
}

.nav-dropdown.nav-dropdown-left {
    right: 0
}

.nav-dropdown>li>.nav-dropdown-left {
    left: auto;
    right: 100%
}

.navigation-landscape .nav-dropdown-left>li>a {
    text-align: right
}

.navigation-portrait .nav-dropdown>li>a {
    padding: 12px 20px 12px 30px
}

.navigation-portrait .nav-dropdown>li>ul>li>a {
    padding-left: 50px
}

.navigation-portrait .nav-dropdown>li>ul>li>ul>li>a {
    padding-left: 70px
}

.navigation-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>a {
    padding-left: 90px
}

.navigation-portrait .nav-dropdown>li>ul>li>ul>li>ul>li>ul>li>a {
    padding-left: 110px
}

.nav-dropdown .submenu-indicator {
    right: 15px;
    top: 16px;
    position: absolute
}

.menu__list,
.navbar,
nav a {
    position: relative
}

.navigation-portrait .submenu-indicator {
    right: 0;
    top: 0
}

.nav-dropdown .submenu-indicator .submenu-indicator-chevron {
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.navigation-portrait .nav-dropdown .submenu-indicator .submenu-indicator-chevron {
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.nav-dropdown>.focus>a .submenu-indicator-chevron,
.nav-dropdown>li:hover>a .submenu-indicator-chevron {
    border-color: transparent #D70040 #D70040 transparent
}

.navigation-landscape .nav-dropdown-left .submenu-indicator {
    left: 10px
}

.navigation-landscape .nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
    -ms-transform: rotate(135deg);
    transform: rotate(135deg)
}

nav a {
    display: inline-block
}

.header {
    background:#fff;
	z-index:4;
}

.header-top {
    padding: 1em 0;
    background: #05222A
}

.indicate {
    float: left
}

.deatils {
    float: right
}

.indicate a {
    font-size: 1.1em;
    color: #C6C7C7;
    vertical-align: middle
}

.deatils ul li i,
.indicate i {
    font-size: 1.2em;
    color: #888f90;
    vertical-align: middle
}

.indicate i {
    margin-right: .5em
}

.deatils ul li {
    display: inline-block;
    margin: 0 5px
}

.deatils ul li i {
    margin-right: .5em
}

.deatils ul li a {
    font-size: 1.1em;
    color: #C6C7C7;
    vertical-align: middle
}

.social-icons {
    float: right;
    margin-top: .7em
}


.logo h1 a {
    color: #fff;
    font-size: 1.3em
}

.logo span {
    display: block;
    font-size: .32em;
    letter-spacing: 4px
}

.header-bottom {
    padding: 1em 0
}

.navbar-nav {
    float: left;
    margin: 0
}

.navbar-default {
    background: #fff;
    border: none !important
}

.navbar-default .navbar-nav>li>a {
    color: #05222A;
    font-size: 1.3em;
    font-weight: 900
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: #02B875;
    background-color: #fff
}

.navbar {
    min-height: 50px;
    margin-bottom: 0;
    border: 1px solid transparent
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #05222A;
    background-color: #fff
}

.menu__list {
    -webkit-flex-wrap: inherit;
    flex-wrap: inherit
}

.menu__item {
    display: block;
    margin: 1em 0
}

.menu__link {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none
}

.menu--francisco .menu__item {
    margin: 0 1.5em
}

.menu--francisco .menu__link {
    position: relative;
    overflow: hidden;
    height: 3em;
    padding: 1em 0;
    text-align: center;
    color: #b5b5b5;
    -webkit-transition: color .3s;
    transition: color .3s
}

.menu--francisco .menu__link:focus,
.menu--francisco .menu__link:hover {
    color: #929292
}

.menu--francisco .menu__item--current .menu__link {
    color: #02B875
}

.menu--francisco .menu__link::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    opacity: 0;
    background: #02B875;
    -webkit-transform: translate3d(0, -3em, 0);
    transform: translate3d(0, -3em, 0);
    -webkit-transition: -webkit-transform 0s .3s, opacity .2s;
    transition: transform 0s .3s, opacity .2s
}

.menu--francisco .menu__item--current .menu__link::before,
.menu--francisco .menu__link:hover::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transition: -webkit-transform .5s, opacity .1s;
    transition: transform .5s, opacity .1s;
    -webkit-transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.menu--francisco .menu__helper {
    display: block;
    pointer-events: none
}

.menu--francisco .menu__item--current .menu__helper,
.menu__link:hover .menu__helper {
    -webkit-animation: anim-francisco .3s forwards;
    animation: anim-francisco .3s forwards
}

@-webkit-keyframes anim-francisco {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes anim-francisco {
    50% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }
    51% {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }
    100% {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}
.nav-menu.nav-menu-social>li.login_click {
    border-radius: 2px;
    top: 19px;
    position: relative;
    height: 45px;
    padding: 0px;
    margin-left: 10px;
}
.nav-menu.nav-menu-social>li.login_click.style-2 {
    border-radius:4px;
    min-width: 140px;
    top:17px;
    position: relative;
    height:46px;
}
.nav-menu.nav-menu-social>li.login_click a {
    top:0px;
	padding: 30px 20px;
    color:#ffffff !important;
}
.nav-menu.nav-menu-social>li.login_click.dark-bg {
    background: #2e3654;
}

.nav-menu.nav-menu-social>li.login_click.bg-whit{
    background:#ffffff !important;
}

.nav-menu.nav-menu-social>li.login_click.bg-whit a{
    color:#333333 !important;
}
.nav-brand img {
    max-width: 120px;
    position: relative;
    top: 4px;
}
.nav-menu.nav-menu-social>li.login_click {
    border-radius:4px;
    top:20px;
    position: relative;
    height:42px;
    padding: 0 5px;
    margin-left: 10px;
    background:#131b31;
	border:1px solid #131b31;
}
.nav-menu.nav-menu-social>li.login_click.search {
    border-radius:4px;
    top:20px;
    position: relative;
    height:42px;
    padding: 0 5px;
    margin-left: 10px;
    background:transparent;
	border:none;
}
.login_click.search .form-inline.addons .form-control {
    border-radius: 3px 0px 0px 3px;
	height: 42px;
    font-size: 13px;
}
.login_click.search .form-inline.addons button {
    border-radius: 0 3px 3px 0;
    height: 42px;
    padding: 0 15px 0 0px;
}
.nav-menu.nav-menu-social>li.login_click.bg-green {
    background: #00ba74;
    border: 1px solid #00ba74;
}
.nav-menu.nav-menu-social>li.login_click.bg-red {
    background:#da0b4e;
    border: 1px solid #da0b4e;
}
.nav-menu.nav-menu-social>li.login_click.bg-blue {
    background:#2983f5;
    border: 1px solid #2983f5;
}
.nav-menu.nav-menu-social>li.login_click.light {
	transition:all ease 0.4s;
    background:#ffffff;
	border:1px solid rgba(100, 123, 156,0.3) !important;
}
.nav-menu.nav-menu-social>li.login_click.light a{
	color:#da0b4e !important;
}
.nav-menu.nav-menu-social>li.login_click.light.green a{
	color:#202b44 !important;
}
.nav-menu.nav-menu-social>li.login_click.light:hover, .nav-menu.nav-menu-social>li.login_click.light:focus{
	background:#da0b4e;
	border-color:#da0b4e !important;
}
.nav-menu.nav-menu-social>li.login_click.light:hover a, .nav-menu.nav-menu-social>li.login_click.light:focus a{
	color:#ffffff !important;
}
/*--------- Dashboard Dropdown ----------*/
.btn-group.account-drop {
    position: relative;
    padding: 19px 15px;
}
.account-drop .dropdown-menu a {
    padding: 0.6rem 0;
    font-size: 14px;
}
.account-drop .dropdown-menu {
    top: 60px !important;
    right: 0 !important;
    background: #fff;
    box-shadow: none;
    border: solid 1px #ebedf3;
    min-width: 200px;
    left: initial !important;
    padding: 0.4rem 1rem;
}
/*----------Dark Menu --------*/
@media (min-width: 992px) {
.nav-menu.nav-menu-social>li.login_click.bg-white{
    background:#ffffff !important;
}
.nav-menu.nav-menu-social>li.login_click.bg-white a{
    color:#333333 !important;
}
.header.header-fixed{
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background: #ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}
.header.head-shadow{
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
}
.nav-menu.nav-menu-social>li.login_click a {
    top: -11px;
}
.nav-menu.nav-menu-social>li.login_click a {
    /* top: 0; */
    padding:20px 15px;
    color: #fff!important;
    font-weight: 600;
    font-size: 15px;
}
.nav-menu.nav-menu-social>li.login_click.style-2 a {
    top: -16px;
}
.header.header-dark {
    background-color: #1f263e;
}
.header.header-dark .nav-brand, .header.header-dark .nav-brand:focus, .header.header-dark .nav-brand:hover, .header.header-dark .nav-menu>li>a {
    color: #ffffff;
}

.header.header-dark .nav-menu>li .nav-dropdown {
    background:#232b46;
}
.header.header-dark .nav-dropdown>li>a {
    border-bottom: 1px solid #2e3654;
}
.header.header-dark .nav-menu>li>.nav-dropdown:before {
  background-color:#232b46;
}

/*------ transparent --------*/
.header.header-transparent {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 100%;
    z-index:4;
    border-bottom: 1px solid rgba(255,255,255,0.1);
}
.header.header-transparent.header-fixed{
    box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    -webkit-box-shadow: 0 5px 30px rgba(0, 22, 84, 0.1);
    background:#ffffff;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}
.header.header-transparent .nav-brand, .header.header-transparent .nav-brand:focus, .header.header-transparent .nav-brand:hover, .header.header-transparent .nav-menu>li>a {
    color: #ffffff;
}
.header.header-transparent.header-fixed .nav-brand, .header.header-transparent.header-fixed .nav-brand:focus, .header.header-transparent.header-fixed .nav-brand:hover, .header.header-transparent.header-fixed .nav-menu>li>a {
    color:#404656;
}
.change-logo .nav-brand.fixed-logo {
    display: none;
}
.change-logo.header-fixed .nav-brand.fixed-logo {
    display: block;
}
.change-logo.header-fixed .nav-brand.static-logo {
    display: none;
}

.dark-text .nav-brand, .header-transparent.dark-text .nav-brand:focus, .header-transparent.dark-text .nav-brand:hover, .header-transparent.dark-text .nav-menu>li>a {
    color: #404656;
}
.header-transparent.dark-text .nav-menu>li>a:hover, .header-transparent.dark-text .nav-menu>li>a:focus {
    color:#D70040;
}
}




/*
DROPDOWN STYLE
=========================== */
nav .menu > li.dropdown > a:before, nav .menu > li.megamenu > a:before{
    margin-left: 10px;
    content:"\e61a";
	font-family:'themify';
    float: right;
    position: relative;
	font-size:12px;
    top:2px;
}
nav .menu > li.dropdown  li.dropdown > a:before{
    margin-left: 10px;
    content:"\e649";
	font-family:'themify';
    float: right;
    position: relative;
	font-size:14px;
    top:1px;
}

nav .menu li.dropdown.open > a{
    color: #e74c3c;
	
}
.top-header {
    background: #333c56;
    padding:12px 0;
    color: #ffffff;
}
.cn-info ul {
    padding: 0;
    margin: 0;
}
.cn-info ul li {
    list-style:none;
	display:inline-block;
	margin-right:15px;
}
.cn-info ul li i{
	margin-right:5px;
}
.cn-info ul li:last-child{
	margin-right:0;
}
.top-social{
	padding: 0;
    margin: 0;
	float:right;
}
.top-social li{
	list-style:none;
	display:inline-block;
	margin-right:15px;	
}
.top-social li:last-child{
	margin-right:0;
}
.top-social li a{
	color:#ffffff;
}
.mg-menu-items{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding:0;
}
.mg-menu-items i{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
}
.mg-menu-items h5{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 16px;
	font-size: 15px;
	color:#031b4e;
	margin-bottom:0;
}
.mg-menu-items h5>span {
    color:#72809D;
    display: block;
    font-weight: 500;
    font-family: 'Muli', sans-serif;
}

.account-drop .btn.btn-order-by-filt {
    padding: 0;
    box-shadow: none;
	margin-top: 5px;
	font-weight: 600;
}
.account-drop .btn.btn-order-by-filt img.avater-img {
    width: 35px;
    border-radius: 50%;
    margin-right: 7px;
}
li.login-attri .account-drop a {
    height: auto;
    line-height: 1.5;
    padding:10px 10px !important;
    box-shadow: none;
    color: #606c8e !important;
    font-size: 15px;
}


/*===================================================
 Homes Banner
==================================================*/
.hero_banner {
    padding:80px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
	overflow: hidden;
}
.half_banner {
    padding:70px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.half_banner.radius {
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0.6rem;
}
.hero_banner {
    justify-content: center;
}
.image-cover {
    background-size: cover !important;
    background-position: center !important;
}
.image-cover.bottom {
    background-position:bottom !important;
}
.hero_banner .container, .half_banner .container {
    position: relative;

}
.hero-caption {
    text-align: left;
    position: relative;
    width: 100%;
    max-width:800px;
}
.hero-caption.min {
    max-width:750px;
}
.hero-caption.small_wd {
    max-width: 650px;
}
.hero-caption.esmall_wd {
    max-width:450px;
}
.hero-caption.center {
    display: table;
    margin: 0 auto;
    text-align: center;
}
.hero-caption p {
    font-size: 17px;
    margin-top:0.2rem;
    line-height: 1.9;
}
.owns_img {
    position: absolute;
    right:5%;
    top:0;
}
.owns_img img {
    max-width: 325px;
}
/*---------- Home 3 ------------*/
.hero-inner-2:after {
    content: "";
    /* background: url(../img/inner-2.png) center center/cover no-repeat local; */
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
	opacity:1;
    z-index: 1;
}
.rlt{
	position:relative;
}
.elix_img_box{
	display:none;
}

/*----------- Home 5 ------------*/
.vw-lg-50 .rounded {
    border-radius: 4rem 0 0 0 !important;
}

/*-------- Search Form -------------*/
.banner-search {
    display: table;
    position: relative;
	overflow: hidden;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
}
.banner-search.shadow_high {
    box-shadow:0px 10px 12px rgba(77, 60, 160, 0.1);
	-webkit-box-shadow:0px 10px 12px rgba(77, 60, 160, 0.1);
}
.banner-search.shadow {
	box-shadow:0px 64px 100px rgba(77, 60, 160, 0.09);
	-webkit-box-shadow:0px 64px 100px rgba(77, 60, 160, 0.09);
}
.search_hero_wrapping {
    height: 100%;
    width: 100%;
    position: relative;
}
.banner-search .form-group {
    margin: 0;
    height: 65px;
}
.banner-search .form-control {
    border: none !important;
}
.banner-search span.select2-selection.select2-selection--single {
    border: none !important;
}

.banner-search .form-group>*, .banner-search .form-group .input-with-icon>* {
    height: 100%;
}
.select2-container--default .select2-selection--single {
    height: 100%;
    display: flex;
    align-items: center;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
    position: absolute;
    top: 1px;
    right: 10px;
    width: 20px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #e6eaf3;
}
.select2-dropdown {
    border: 1px solid #e6eaf3;
}
.banner-search .btn.search-btn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 0;
    font-size: 18px;
	border-radius:0;
    position: relative;
    right: -1px;
	color:#ffffff;
}

/*------------- Home 5 Style -----------*/
.circle_flixio{
    position: absolute;
    top: 10px;
}
.flixio {
    position: relative;
    z-index: 1;
}
.flixio img{
	max-height:670px;
	display:table;
	margin:0 auto;
}
/*------ Input Field with Icon ----------*/
.input-with-icon {
    position: relative;
    padding-left: 40px;
}
.input-with-icon img {
    position: absolute;
    left: 15px;
    max-width: 25px;
    top:1px;
}
.input-with-icon i {
    position: absolute;
    left: 14px;
    font-size: 25px;
    color: #BFCADA;
    top: 38%;
}

/*--------- Search 2 Style ----------*/
.input-group-append img {
    max-width: 20px;
}
.input-group {
    border: 1px solid #e6eaf3;
    border-radius:0.2rem;
    overflow: hidden;
}
.banner-search-2 .form-control {
    height:58px;
	border:none !important;
}
.banner-search-2 {
    margin: 1.5rem 0;
    padding: 2rem 1.5rem;
    background: #ffffff;
    border-radius:0.2rem;
    max-width: 500px;
}
.banner-search-2.transparent {
    margin: 1.5rem 0;
    padding:0 0 0 0rem;
    background:transparent;
    border-radius:0.2rem;
    max-width: 500px;
}
.banner-search-2 .btn-outline-secondary:hover, .btn-outline-secondary:focus {
    background: #ffffff;
    border-color:#ffffff;
    box-shadow: none;
}

/*===================================================
 Education Elements
==================================================*/
/*------------- Education Grid Style ----------------*/
.education_block_grid{
    width: 100%;
    background: #ffffff;
    border-radius:0.4rem;
	overflow:hidden;
    margin-bottom: 30px;
    position: relative;
	transition:all ease 0.4s;
    border:1px solid #e8ebf1;
}
.education_block_grid:hover, .education_block_grid:focus{
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}
.education_block_body{
    padding:20px;
}
.education_block_thumb {
    overflow: hidden;
    position: relative;
}
.education_block_thumb:before {
    content: "";
    position: absolute;
    background: #2a2f4c;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0.2;
}
.education_block_thumb.n-shadow:before{
	display:none;
}
.cources_price {
    position: absolute;
    top: 15px;
    left: 15px;
    background: #ffffff;
    padding: 4px 15px;
    border-radius: 2px;
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255,0.2);
    font-weight: 600;
    color: #393d4e;
}
.education_ratting {
    position: absolute;
    left: 0px;
    bottom: 30px;
    background: #ffffff;
    padding: 8px 15px;
    border-radius: 0px 50px 50px 0;
    font-size: 13px;
    color: #515a6b;
    font-weight: 600;
}
.education_ratting i {
    margin-right: 6px;
    color: #FF9800;
}
.education_block_grid .bl-title {
    font-size: 17px;
    margin-bottom:10px;
}
.education_block_body p {
    font-size: 14px;
	margin-bottom:0;
}
.education_block_body {
    padding: 20px;
}
.education_block_author .path-img {
    max-width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    margin-right:7px;
}
.education_block_author {
    flex: 1;
    display: flex;
    align-items: center;
}
.education_block_author h5 {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    opacity: 1;
}
.education_block_author h5 a {
    color: #4a5874;
}
.education_block_footer {
    padding: 1rem 1.5rem;
    width: 100%;
    display: flex;
    vertical-align: middle;
    align-items: center;
    border-top: 1px solid #f0f1f5;
}
.education_block_author {
    flex: 1;
}
.education_block_footer .education_block_time{
    font-size: 13px;
    color: #808fa0;
}

/*----------- Style 2 -------------*/
.education_block_grid.style_2 .education_block_body {
    padding: 20px 20px 5px;
}
.cources_facts {
    position: relative;
    width: 100%;
    padding: 0 20px;
}
ul.cources_facts_list {
    margin: 0;
    padding: 0;
}
ul.cources_facts_list li {
    display: inline-block;
    margin-right:0.8rem;
}
ul.cources_facts_list li:last-child {
    margin-right:0rem;
}
ul.cources_facts_list li {
	padding:3px 8px;
    font-size: 13px;
	position: relative;
    background: #f4f5f7;
    border-radius:0 2px 2px 0;
}

ul.cources_facts_list li.facts-1 {
    background: rgba(33, 177, 124,0.13);
    color: #21b17c;
}
ul.cources_facts_list li.facts-2{
    background: rgba(255, 118, 0,0.13);
    color: #ff7600;
}
ul.cources_facts_list li.facts-3{
    background: rgba(197, 20, 59,0.13);
    color: #da0b4e;
}
ul.cources_facts_list li.facts-4{
    background: rgba(18, 163, 218,0.13);
    color: #12a3da;
}
ul.cources_facts_list li.facts-5{
    background: rgba(138, 101, 251,0.13);
    color: #8a65fb;
}
ul.cources_facts_list li:before {
    border-top: 12px solid transparent;
    border-left: 10px solid #eaedf3;
    border-bottom: 13px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    right: -10px;
    width: 0;
}
ul.cources_facts_list li.facts-1:before {
    border-left-color:rgba(33, 177, 124,0.13);
}
ul.cources_facts_list li.facts-2:before {
    border-left-color:rgba(255, 118, 0,0.13);
}
ul.cources_facts_list li.facts-3:before {
    border-left-color:rgba(197, 20, 59,0.13);
}
ul.cources_facts_list li.facts-4:before {
    border-left-color:rgba(18, 163, 218,0.13);
}
ul.cources_facts_list li.facts-5:before {
    border-left-color:rgba(138, 101, 251,0.13);
}
ul.cources_facts_list li:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 4px;
    position: absolute;
    top:10px;
    right: -5px;
    width: 4px;
}
.cources_info {
    width: 100%;
    display: flex;
	padding:30px 20px;
    align-items: center;
}
.cources_info_first {
    flex: 1;
    color: #4a5874;
}
.cources_info_first ul {
    margin: 0;
    padding: 0;
}
.cources_info_last h3 {
    margin: 0;
	font-size:30px;
}

/*--------- Style 3 ------------*/
.cources_info_style3 {
    padding: 0 20px 20px;
}
.cources_info_style3 ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
}
.cources_info_style3 ul li {
    flex: 0 0 33.333333%;
    width: 33.333333%;
    font-size: 13px;
	color:#4a5874;
}
.cources_info_style3 ul li i {
    font-size: 14px;
}
.cources_price_foot {
    font-size: 28px;
    font-weight: 800;
    color: #4a5874;
    display: flex;
    align-items: center;
}
.cources_price_foot .price_off {
    font-size: 13px;
    margin-right:0.6rem;
    text-decoration: line-through;
    opacity: 0.7;
}
.foot_lecture {
    background: rgba(30, 173, 107,0.13);
    color: #1ead6b;
    padding: 6px 15px;
    font-size: 13px;
    border-radius: 50px;
}

/*--------------- List Style ------------------*/
.education_block_list_layout{
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    border: 1px solid #e8ebf1;
    background: #ffffff;
    border-radius: 0.4rem;
    margin-bottom: 30px;
	padding:1rem;
}
.education_block_list_layout .education_block_thumb {
    width: 200px;
    height: 180px;
    margin: 0;
    border-radius: 0.4rem;
    overflow: hidden;
}
.education_block_list_layout .education_block_thumb img {
    width: 100%;
    align-items: center;
    object-fit: cover;
    height: 100%;
}
.education_block_list_layout .list_layout_ecucation_caption {
    display: block;
    width: auto;
    flex: 1;
}
.education_block_list_layout .education_block_body .bl-title {
    font-size: 17px;
    line-height: 1.2;
	margin:0;
}
.education_block_list_layout .education_block_body .bl-title a {
    color: #2D3954;
}
.education_block_list_layout .course_rate_system {
    display: flex;
    align-items: center;
    width: 100%;
}
.education_block_list_layout .course_ratting {
    margin-right: 12px;
}
.education_block_list_layout .course_ratting i {
    font-size: 10px;
    color: #ccd4e6;
}
.education_block_list_layout .course_ratting i.filled{
    color:#ff9800;
}
.education_block_list_layout .course_reviews_info {
    font-size: 12.5px;
    font-weight: 400;
}
.education_block_list_layout .course_reviews_info strong {
    font-size: 16px;
    margin-right: 1px;
}
.education_block_list_layout .course_reviews_info strong.mid {
    color: #FF9800;
}
.education_block_list_layout .course_reviews_info strong.good {
    color:#11b176;
}
.education_block_list_layout .course_reviews_info strong.high {
    color:#8bc34a;
}
.course_reviews_info strong.poor {
    color:#e91e63;
}
.education_block_list_layout .education_block_footer {
    padding: 0.5rem 1.5rem;
    border: none;
}
.education_block_list_layout .cources_info_style3 ul li {
    width: 100%;
    flex: 0 0 100%;
}
.education_block_list_layout .cources_info_style3 {
    padding: 0;
    margin: 0;
}
.education_block_list_layout .cources_price {
    position: relative;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    font-size: 20px;
    font-weight: bold;
    color: #203675;
}
.education_block_list_layout .less_offer {
    margin-left: 8px;
    font-size: 13px;
    /* font-weight: 400; */
    opacity: 0.5;
    text-decoration: line-through;
}
.education_block_list_layout  .education_block_body {
    padding: 0 20px 20px;
}
/*------------- Watching Videos ---------------*/
.edu-watching {
    position: relative;
    width: 100%;
    display: block;
    margin-bottom: 30px;
}
.edu-watching .property_video .thumb, .edu-watching .property_video:before {
    border-radius: 0.4rem;
}
.edu_video_header {
    margin-bottom:5px;
}
.edu_video_header h4 {
    font-size:15px;
    margin: 0;
}
.edu_video.detail {
    padding: 12px;
    width: 100%;
    position: relative;
    display: table;
}
.edu_video_bottom {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}
.edu_video_bottom_left {
    flex: 1;
}
.edu_video_bottom_right i {
    margin-right: 5px;
}
.edu_video_bottom_right {
    display: flex;
    align-items: center;
}
.edu_duration {
    position: absolute;
    right: 25px;
    bottom: 15px;
    background: #ffffff;
    padding: 5px 15px;
    border-radius: 6px;
    font-weight: 600;
    color: #131b31;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    line-height: 0;
}
/*--------------------- Trips Design ----------------*/
.trips_top {
    position: relative;
    margin-top: -50px;
}
.trips_wrap {
    background: #ffffff;
    border-radius: 15px;
    padding: 0;
    box-shadow: 0 0 20px 0 rgba(62,28,131,0.1);
}
.trips {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 1rem;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
}
.trips_icons {
    width: 55px;
    height: 55px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 12px;
    background: rgba(0, 186, 116,0.1);
    border-radius: 50%;
    color: #00ba74;
    font-size: 1.3rem;
}
.light-colors .trips_icons {
    background:rgba(218, 11, 78,0.1);
    color:#da0b4e;
}
.trips_detail {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.trips_detail h4 {
    font-size: 15px;
    margin-bottom: 0;
}
.trips_detail p {
    margin: 0;
    font-size: 15px;
}

/*----------- Colored ---------------*/
.trips_wrap.full{
	border-radius:0;
}
.trips_wrap.colored{
    background: #da0b4e;
}
.trips_wrap.colored .trips {
    border-right: 1px solid rgba(255, 255,255,0.2);
}
.trips_wrap.colored .trips.none{
	border:none;
}
.trips_wrap.colored .trips_detail h4, .trips_wrap.colored .trips_detail p {
    color: #ffffff;
}
.trips_wrap.colored .trips_icons{
	background:rgba(255,255,255,0.1);
    color: #ffffff;
}
.trips_wrap.colored.style_2 .trips_icons{
	color: #131b31;
    background:#ffffff;
}

/*------------ Course Facts Short -------------*/
.cource_facts {
    display: block;
    width: 100%;
    padding: 1.5rem 0;
}
.cource_facts ul {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0;
    margin: 0;
}
.cource_facts ul li {
    font-size: 15px;
    font-weight: 400;
    color: #647b9c;
	width: 33.333333%;
    flex: 0 0 33.333333%;
}
.cource_facts ul li span{
	font-size:40px;
	font-weight: 700;
	line-height: 1.2;	
	display:block;
}
/*----------- Modern Btn Style ------------*/
.btn.btn-modern {
    background: rgba(218, 11, 78,0.1);
	transition:all ease 0.4s;
    color: #da0b4e;
    border-radius: 6px;
    height: 54px;
    padding: 0 5px 0 25px;
    display: flex;
    width: max-content;
    align-items: center;
    justify-content: center;
}
.btn.btn-modern span {
    height: 44px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
	transition:all ease 0.4s;
    position: relative;
    background: #da0b4e;
    border-radius: 4px;
    color: #ffffff;
    margin-left: 20px;
}
.btn.btn-modern:hover, .btn.btn-modern:focus{
	background:#da0b4e;
	color: #ffffff;
}
.btn.btn-modern:hover span, .btn.btn-modern:focus span{
	background:#ffffff;
	color:#da0b4e;
}

/*---------- Black Color -------------*/
.btn.btn-modern.black{
    background:#131b31;
    color:#ffffff;
}
.btn.btn-modern.black span {
    background:#1f2a48;
    color: #ffffff;
}
.btn.btn-modern.black:hover, .btn.btn-modern.black:focus{
	background:#da0b4e;
	color: #ffffff;
}
.btn.btn-modern.black:hover span, .btn.btn-modern.black:focus span{
	background:#ffffff;
	color:#da0b4e;
}

/*---------- Green Color -------------*/
.btn.btn-modern.green{
    background:rgba(0, 186, 116,0.1);
    color:#00ba74;
}
.btn.btn-modern.green span {
    background:#00ba74;
    color: #ffffff;
}
.btn.btn-modern.green:hover, .btn.btn-modern.green:focus{
	background:#00ba74;
	color: #ffffff;
}
.btn.btn-modern.green:hover span, .btn.btn-modern.green:focus span{
	background:#ffffff;
	color:#00ba74;
}

/*---------- Red Dark Color -------------*/
.btn.btn-modern.dark{
    background:#da0b4e;
    color:#ffffff;
}
.btn.btn-modern.dark span {
    background:#ffffff;
    color:#da0b4e;
}
.btn.btn-modern.dark:hover, .btn.btn-modern.dark:focus{
	background:#ffffff;
	color:#da0b4e;
}
.btn.btn-modern.dark:hover span, .btn.btn-modern.dark:focus span{
	background:#da0b4e;
	color:#ffffff;
}

/*---------- Green Dark Color -------------*/
.btn.btn-modern.green_dark{
    background:#00ba74;
    color:#ffffff;
}
.btn.btn-modern.green_dark span {
    background:#ffffff;
    color:#00ba74;
}
.btn.btn-modern.green_dark:hover, .btn.btn-modern.green_dark:focus{
	background:#ffffff;
	color:#00ba74;
}
.btn.btn-modern.green_dark:hover span, .btn.btn-modern.green_dark:focus span{
	background:#00ba74;
	color:#ffffff;
}
/*----------------- Facts List --------------*/
.list_facts_wrap {
    margin: 0;
    display: block;
    width: 100%;
    max-width: 80%;
}
.list_facts {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom:1.5rem;
}
.list_facts_icons {
    width: 45px;
    height: 45px;
    background: rgba(217, 11, 78,0.1);
	box-shadow:0 7px 15px rgba(0, 22, 84, 0.1);
	-webkit-box-shadow:0 7px 15px rgba(0, 22, 84, 0.1);
    border-radius: 6px;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #d90b4e;
}
.green .list_facts_icons {
    background:rgba(0, 186, 116,0.1);
    color:#00ba74;
}
.list_facts_caption h4 {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
}
.list_facts_caption p {
    margin: 0;
	font-size:14px;
}
/*------------------- Education Category -------------------*/
.edu_cat{
    box-shadow: 0px 0px 20px rgba(0,0,0,.075);
	-webkit-box-shadow: 0px 0px 20px rgba(0,0,0,.075);
	background:#ffffff;
	border-radius:0.4rem;
	margin-bottom: 30px;
	overflow:hidden;
}
.edu_cat .pic {
    position: relative;
    padding-top: calc((65/100)*100%);
}
.edu_cat .pic>.pic-main {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: center center no-repeat;
    background-size: cover;
}
.edu_data{
    padding: 28px 30px 30px;
}
.edu_data .title {
	font-size: 20px;
	line-height: 35px;
	margin-bottom:2px;
}
.edu_data .title a {
	color:#2d3954;
}
.edu_data ul{
	margin:0;
	padding:0;
}
.edu_data ul li{
	margin-right:15px;
	font-size:14px;
	display:inline-block;
}
.edu_data ul li i{
	margin-right:5px;
}

.edu_cat_2 {
    width: 100%;
	margin-bottom:25px;
    border-radius: 0.4rem;
    background: #f4f5f7;
    padding: 1.5rem 1rem;
    display: flex;
    align-items: center;
	cursor:pointer;
	transition:all ease 0.4s;
}
.edu_cat_2:hover,.edu_cat_2:focus {
	box-shadow: 0px 0px 20px rgba(0,0,0,.075);
	-webkit-box-shadow: 0px 0px 20px rgba(0,0,0,.075);
}
.edu_cat_icons {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.edu_cat_icons img{
    max-width:40px;
}
.edu_cat_data {
    padding-left: 15px;
}
.edu_cat_data h4 {
    font-size: 17px;
    margin-bottom: 4px;
}
.edu_cat_data ul li i {
    margin-right: 6px;
}
.edu_cat_2.cat-1{
	background:rgba(0, 173, 182,0.1);
}
.edu_cat_2.cat-1 h4>a{
	color:#00adb6;
}
.edu_cat_2.cat-2{
	background:rgba(247, 179, 32,0.1);
}
.edu_cat_2.cat-2 h4>a{
	color:#f7b320;
}
.edu_cat_2.cat-3{
	background:rgba(241, 7, 80,0.1);
}
.edu_cat_2.cat-3 h4>a{
	color:#f10750;
}
.edu_cat_2.cat-4{
	background:rgba(154, 222, 69,0.1);
}
.edu_cat_2.cat-4 h4>a{
	color:#9ade45;
}
.edu_cat_2.cat-5{
	background:rgba(247, 179, 32,0.1);
}
.edu_cat_2.cat-5 h4>a{
	color:#f7b320;
}
.edu_cat_2.cat-6{
	background:rgba(185, 95, 253,0.1);
}
.edu_cat_2.cat-6 h4>a{
	color:#b95ffd;
}
.edu_cat_2.cat-7{
	background:rgba(236, 80, 152,0.1);
}
.edu_cat_2.cat-7 h4>a{
	color:#ec5098;
}
.edu_cat_2.cat-8{
	background:rgba(37, 165, 95,0.1);
}
.edu_cat_2.cat-8 h4>a{
	color:#25a55f;
}
.edu_cat_2.cat-9{
	background:rgba(234, 105, 13,0.1);
}
.edu_cat_2.cat-9 h4>a{
	color:#ea690d;
}
.edu_cat_2.cat-10{
	background:rgba(13, 214, 234,0.1);
}
.edu_cat_2.cat-10 h4>a{
	color:#0dd6ea;
}

/*--------------- Instructor --------------*/
.instructor_wrap {
    width: 100%;
    position: relative;
	display: table;
	border-radius:0.3rem;
	background:#ffffff;
    box-shadow: 0 2px 10px rgba(0, 22, 84, 0.1);
    text-align: center;
    padding:3em 1rem;
	margin-bottom:30px;
}
.instructor_thumb {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: 1rem auto;
    display: table;
}
.instructor_caption h4 {
    font-size: 18px;
    margin-bottom: 0px;
}
.instructor_caption ul {
    margin: 10px auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.instructor_caption ul li {
    padding: 0 8px;
}
ul.st_rate_info li {
    margin-right: 2px;
    font-size: 12px;
    display: inline-block;
    color: #c5cee2;
}
ul.st_rate_info li .filled {
    color: #FF9800;
}
.single_instructor {
    display: flex;
    align-items: center;
    width: 100%;
	padding: 12px;
	border-radius:0.5rem;
    margin-bottom: 30px;
	background:#ffffff;
    overflow: hidden;
}
.single_instructor.border{
    border: 1px solid #e7ecf5;
}

.single_instructor_thumb {
    width: 230px;
    height: 230px;
    border-radius: 4px;
    overflow: hidden;
}
.single_instructor_thumb img {
    max-width: 100%;
    border-radius: 4px;
}
.single_instructor_caption {
    padding-left: 2rem;
	flex: 1;
}
.single_instructor_caption h4 {
    margin-bottom: 3px;
}
ul.instructor_info {
    padding: 0;
    margin: 0;
	margin-bottom:1rem;
}
ul.instructor_info li {
    display: inline-block;
    margin-right: 1rem;
	font-size:13px;
}
ul.instructor_info li:last-child{
    margin-right:0rem;
}
ul.instructor_info li i{
    margin-right:7px;
}
ul.social_info{
	margin-top:15px;
}
ul.social_info li {
    display: inline-block;
    margin-right: 15px;
}
ul.social_info li a {
    width: 36px;
    height: 36px;
    display: flex;
    background: #ffffff;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbe2ef;
    border-radius: 50%;
    font-size: 12px;
}
/*------------- Style 2 ------------------*/
.instructor_wrap.mid .cources_info_style3 ul li {
    flex: 0 0 50%;
    width: 50%;
}
.instructor_wrap.mid .cources_info_style3 {
    padding: 0;
	margin-top:2rem;
}
.instructor_wrap.mid {
    padding: 2em 1rem 1.5rem;
}
.instructor_wrap.mid span.skills-min {
    font-size: 13px;
}
.instructor_wrap.mid .instructor_caption ul.colored li a {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    background: #f4f5f7;
    justify-content: center;
    font-size: 12px;
    border-radius: 50%;
}
.instructor_wrap.mid .instructor_caption ul.colored li a.cl-twitter {
    color: #38afe8;
    background: rgba(56, 175, 232, 0.2);
}
.instructor_wrap.mid .instructor_caption ul.colored li a.cl-fb {
    color: #2e4fc7;
    background: rgba(46, 79, 199, 0.2);
}
.instructor_wrap.mid .instructor_caption ul.colored li a.cl-linked {
    background: rgba(9, 157, 181, 0.2);
    color: #099db5;
}
.instructor_wrap.mid .instructor_caption ul.colored li a.cl-google {
    color: #e83800;
    background: rgba(232, 56, 0, 0.2);
}
/*-------------- Instructor Detail ---------------*/
.details_single ul li {
    position: relative;
    display: block;
    margin-bottom: 15px;
    line-height: 1.8;
    padding-left: 30px;
}
.details_single ul li:before {
    content: "\e65d";
    position: absolute;
    font-family: themify;
    font-size: 13px;
    top: 4px;
    left: 0;
}
.details_single ul.skills_info li:before {
    color:#da0b4e;
}
.skills_captions h4 {
    font-size: 15px;
    color:#da0b4e;
    margin-bottom: 0;
}
.skills_captions span {
    font-size: 14px;
    display: block;
}
.skills_captions span {
    font-size: 14px;
    display: block;
}
.details_single p:last-child {
    margin-bottom: 0;
}
.viewer_detail_wraps {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}
.viewer_detail_thumb {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position:relative;
    margin-right: 1rem;
}
.viewer_detail_thumb img{
	border-radius:50%;
	max-width:100%;
}
.caption {
    flex: 1;
    padding-left: 1rem;
}
.viewer_package_status {
    display: inline-block;
    margin-bottom: 5px;
    padding: 3px 12px;
    background: rgba(218, 11, 78,0.2);
    color: #da0b4e;
    border-radius: 2px;
    font-size: 12px;
}
.viewer_header h4 {
    margin-bottom:1px;
}
.viewer_header span{
	margin-bottom:3px;
}
.viewer_header ul li {
    list-style: none;
    display: inline-block;
    margin-right: 1rem;
}
.viewer_header ul li:last-child{
    margin-right:0rem;
}
.viewer_header ul li strong {
    color: #252c41;
}
ul.badge_info{
    margin-top:15px;
}
ul.badge_info li{
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
	margin-right: 12px;
	box-shadow: 0px 0px 0px 3px rgb(241, 243, 247) !important;
	-webkit-box-shadow: 0px 0px 0px 3px rgb(241, 243, 247) !important;
    border: 2px solid #ffffff;
}
ul.badge_info li:last-child{
	margin-right:0;
}
ul.badge_info li.unlock{
    background: #f4f5f7 !important;
	color:#3b5b88 !important;   
}
ul.badge_info li.started {
    color: #d83d3d;
    background:rgba(216, 61, 61,0.1);
}
ul.badge_info li.medium {
    color: #009688;
    background:rgba(0, 150, 136,0.1);
}
ul.badge_info li.platinum {
    color: #ff9800;
    background:rgba(255, 152, 0,0.1);
}
ul.badge_info li.elite {
    color: #a061da;
    background:rgba(160, 97, 218,0.1);
}
ul.badge_info li.power {
    color: #2196f3;
    background:rgba(160, 97, 218,0.1);
}
.viewer_status {
    position: absolute;
    right: 10px;
    bottom: 5px;
    background: #4CAF50;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-radius: 50px;
    color: #ffffff;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
}
.invers .viewer_header ul li strong {
    color: #ffffff;
}
.invers ul.badge_info li {
	box-shadow: 0px 0px 0px 3px #132b56 !important;
    -webkit-box-shadow: 0px 0px 0px 3px #132b56 !important;
    border: 2px solid #0d2248;
}
.invers ul.badge_info li.unlock {
    background: #0d2044 !important;
    color: #3b5b88 !important;
}
/*--------------- Education Detail ----------------*/
.ed_detail_head {
    padding: 2rem;
}
.ed_detail_head.lg{
    padding:4rem;
}
.ed_detail_wrap {
    width: 100%;
    display: block;
    position: relative;
}
.ed_header_caption ul {
    padding: 0;
    margin: 0;
}
.ed_header_caption ul li, .edu_inline_info li {
    display: inline-block;
    margin-right: 1.5rem;
    list-style: none;
    font-size: 14px;
}
.ed_header_caption ul li:last-child, .edu_inline_info li:last-child{
    margin-right: 0rem;
}
.ed_header_caption ul li i, .edu_inline_info li i{
    margin-right:7px;
}
.ed_header_caption {
    width: 100%;
    display: block;
	margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.ed_rate_info {
    display: flex;
    align-items: center;
    width: 100%;
}
.ed_rate_info .star_info {
    margin-right: 10px;
    position: relative;
}
.ed_rate_info .star_info i {
    font-size: 11px;
    color: #ced7e8;
    margin-right: 1px;
}
.ed_rate_info .star_info i.filled {
    color: #f1992c;
}
.review_counter {
    font-size: 12.5px;
}
.review_counter strong {
    font-size: 16px;
}
.review_counter strong.poor {
    color: #de1f1f;
}
.review_counter strong.mid {
    color: #e69110;
}
.review_counter strong.good {
    color: #3db773;
}
.review_counter strong.high {
    color:#7baf17;
}
.ed_detail_wrap.light {
    color: #ffffff;
}
.ed_detail_wrap.light ul.cources_facts_list li {
    background: rgba(255,255,255,0.1) !important;
    color: #ffffff !important;
}
.inline_edu_wrap {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.inline_edu_first {
    flex: 1;
}
.inline_edu_last .enroll-btn {
    border-radius: 0.3rem;
    color: #ffffff !important;
}
.inline_edu_last {
    display: flex;
    align-items: center;
}
.inline_edu_last .edu_price {
    font-size: 31px;
    margin: 0;
    margin-right: 10px;
}
ul.list_ed_detail2 {
    margin: 0px;
    padding: 0;
    width: 100%;
    display: block;
}
ul.list_ed_detail2 li {
    width: 100%;
    margin-bottom:10px;
    display: block;
    padding:15px 15px;
    background: #f4f5f7;
    border-radius:5px;
}
ul.list_ed_detail2 li:last-child{
    margin-bottom:0px;
}
ul.list_ed_detail2 li i {
    margin-right: 15px;
}
ul.list_ed_detail2 li strong{
    margin-right:20px;
}
ul.list_ed_detail2 li.tag-1 {
    background: rgba(47, 175, 92,0.1);
	color: #2faf5c;
}
ul.list_ed_detail2 li.tag-1 strong, ul.list_ed_detail2 li.tag-1 i {
    color: #2faf5c;
}
ul.list_ed_detail2 li.tag-2 {
    background: rgba(255, 152, 1,0.1);
	 color:#ff9800;
}
ul.list_ed_detail2 li.tag-2 strong, ul.list_ed_detail2 li.tag-2 i {
    color:#ff9800;
}
ul.list_ed_detail2 li.tag-3 {
    background: rgba(216, 67, 56,0.1);
	 color:#d84338;
}
ul.list_ed_detail2 li.tag-3 strong, ul.list_ed_detail2 li.tag-3 i {
    color:#d84338;
}
ul.list_ed_detail2 li.tag-4 {
    background: rgba(143, 96, 218,0.1);
	 color:#8f60da;
}
ul.list_ed_detail2 li.tag-4 strong, ul.list_ed_detail2 li.tag-4 i {
    color:#8f60da;
}
ul.list_ed_detail2 li.tag-5 {
    background: rgba(23, 140, 232,0.1);
	color:#2196f3;
}
ul.list_ed_detail2 li.tag-5 strong, ul.list_ed_detail2 li.tag-5 i {
    color:#2196f3;
}
/*----------------- Reviews Students -------------*/
.list-single-main-item {
    padding: 30px 30px;
    border-radius: 100%;
    background: #fff;
    border-radius:0.5rem;
    margin-bottom: 20px;
}
.list-single-main-item.border{
	border: 1px solid #e7ecf5;
}
.list-single-main-item-title {
    margin: 0 0 20px 0;
}

.list-single-main-item-title h3 {
    color: #334e6f;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
}
.reviews-comments-wrap {
    display: inline-table;
    margin-top: 30px;
}
.reviews-comments-item {
    padding: 0 0 30px 110px;
    position: relative;
    float: left;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 1px solid #eee;
}
.reviews-comments-item:last-child {
    padding-bottom: 0;
    border: none;
    margin-bottom: 0;
}
.review-comments-avatar {
    position: absolute;
    top: 0;
    left: 20px;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 100%;
}
.reviews-comments-item-text {
    float: left;
    width: 100%;
    position: relative;
    padding: 0 20px;
}
.reviews-comments-item-text h4 {
    text-align: left;
    padding-bottom: 0px;
    font-size:18px;
    font-weight: 500;
    margin-bottom: 5px;
}
.listing-rating {
    margin-bottom: 1rem;
}
.reviews-comments-item-text .listing-rating i {
    width: 25px;
    height: 25px;
    background:#f4f5f7;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 14px;
	color:#86889a;
}
.reviews-comments-item-text .listing-rating i.active {
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 11px;
    color: #ffffff;
}
.reviews-comments-item-text .listing-rating.high i.active{
	background:#00ba74;
}
.reviews-comments-item-text .listing-rating.good i.active{
	background:#83ce36;
}
.reviews-comments-item-text .listing-rating.mid i.active{
	background:#fbb851;
}
.reviews-comments-item-text .listing-rating.poor i.active{
	background:#e6453c;
}
.reviews-comments-item-text p {
    text-align: left;
}
.reviews-comments-item-date {
    float:right;
    font-weight: 500;
    color: #86889a;
    font-size: 14px;
}
.reviews-comments-item-date i{
	margin-right:10px;
}
.reviews-reaction{
	margin-top:1rem;
}
.reviews-reaction a {
    margin-right: 20px;
	color:#8c97a2;
}
.reviews-reaction a.comment-like.active{
	color:#0eb987;
}

.reviews-reaction a.comment-dislike.active{
	color:#244763;
}

.reviews-reaction a.comment-love.active{
	color:#ea1a57;
}
.review-count {
    font-weight: 600;
}
/*---------- Video -------------*/
.property_video {
    position: relative;
}
.property_video:before {
    background: #17203a;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    content: "";
    bottom: 0;
    opacity: 0.5;
	 border-radius:1rem;
}
.property_video .thumb {
    border-radius:1rem;
    overflow: hidden;
}
.property_video .thumb .pro_img {
    min-height:290px;
    max-height:290px;
}
.property_video.sm .thumb .pro_img {
    min-height:220px;
    max-height:220px;
}
.property_video.lg .thumb .pro_img {
    min-height:340px;
    max-height:340px;
}
.property_video.xl .thumb .pro_img {
    min-height:490px;
    max-height:490px;
}
.property_video .thumb .pro_img {
    width: 100%;
    object-fit: cover;
}
.property_video .bb-video-box {
    width:50px;
    height:50px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px dashed #ffffff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
.bb-video-box-inner {
    width:40px;
    height:40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,1);
}

/*------------ Overview --------------*/
.edu_wraper {
    width: 100%;
    padding: 2rem;
    background: #ffffff;
    border-radius: 0.5rem;
    margin-bottom: 30px;
}
.edu_wraper.border{
    border: 1px solid #e7ecf5;
}
.edu_wraper .edu_title {
    margin-bottom: 1rem;
    font-size: 18px;
}
ul.edu_list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
}
ul.edu_list li {
    width: 100%;
    margin: 0;
    padding: 10px 0;
}
ul.edu_list li i {
    margin-right: 7px;
}
ul.edu_list li strong {
    margin-left: 1rem;
}
ul.edu_list.right li strong {
    float: right;
}
ul.edu_list.third li {
    flex: 0 0 33.333333%;
}
.instructor_over_info ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    width: 100%;
}
.instructor_over_info {
    position: absolute;
    bottom: 20px;
    left: 20px;
}
.ins_info {
    display: flex;
    align-items: center;
}
.ins_info .ins_info_thumb {
    width: 45px;
    height: 45px;
    border-radius:50%;
    overflow: hidden;
    margin-right: 7px;
}
.ins_info_caption span {
    font-size: 13px;
    color: #ffffff;
}
.ins_info_caption h4 {
    font-size: 15px;
    color: #ffffff;
    margin: 0;
    line-height: 1.2;
}
.instructor_over_info ul li {
    margin-right:2.5rem;
    font-size: 15px;
    color: #ffffff;
    font-weight: 600;
}
.instructor_over_info ul li:last-child{
    margin-right:0rem;
}
.instructor_over_info ul li span{
    font-size: 13px;
	font-weight:400;
	display:block;
    color: #ffffff;
}
.eds_rate {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: 600;
}
.eds_rating {
    margin-left: 5px;
    font-size: 13px;
}
.eds_rating i{
    margin-right:2px;
	font-size:10px;
}
.eds_rating i.filled{
    color:#f1992c;
}

/*-------------------- Reviews --------------*/
.rating-overview {
    display: flex;
    margin: 35px 0 25px;
    background: #fff;
    padding: 30px;
    padding-right: 20px;
    border-radius:0.5rem;
}
.rating-overview.border{
    border: 1px solid #e7ecf5;
}
.rating-overview-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: #ffdead;
    width: 135px;
    margin-right: 20px;
    text-align: center;
    border-radius: 0;
    border-right: 1px solid #e8e8e8;
    padding-right: 27px;
}
.rating-overview-box-total {
    font-size: 58px;
    font-weight: 700;
    line-height: 1em;
    display: block;
    color: #2a2f3a;
}
.rating-overview-box-percent {
    font-size: 15px;
    margin-bottom: 0;
    display: block;
}

.rating-overview-box .star-rating {
    font-size: 16px;
    margin: 0;
    display: block;
    letter-spacing: -.5px;
}
.rating-bars {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 0;
    padding: 0;
    margin-top: 0;
    align-items: center;
}
.rating-bars-item {
    margin: 10px 15px;
    width: calc(50% - 30px);
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1366px){
.rating-bars-item {
    width: calc(50% - 30px);
}
}
.rating-bars-name {
    font-weight: 600;
    color:#4c4f5a;
    display: block;
    font-size: 14px;
    line-height: 22px;
}
.rating-bars-inner {
    display: flex;
    width: 100%;
}
.rating-bars-rating {
    display: inline-block;
    vertical-align: baseline;
    background:#f4f5f7;
    width: 100%;
    height: 6px;
    margin-right: 10px;
    border-radius: 3px;
    overflow: hidden;
    background-color:#f4f5f7;
    align-self: center;
}
.rating-bars-rating-inner {
    height: 6px;
    display: block;
    background-color:#f4f5f7;
    position: relative;
    width: 0;
    transition: width .5s;
}
.rating-bars-rating.high .rating-bars-rating-inner {
    background-color:#00ba74;
}
.rating-bars-rating.good .rating-bars-rating-inner {
    background-color:#83ce36;
}
.rating-bars-rating.mid .rating-bars-rating-inner {
    background-color:#fbb851;
}
.rating-bars-rating.poor .rating-bars-rating-inner {
    background-color:#e6453c;
}
.rating-bars-item strong {
    color:#4c4f5a;
    font-size: 12px;
    font-weight: 600;
    align-self: center;
    background:#f4f5f7;
    border-radius: 50px;
    width: 46px;
    height: 22px;
    text-align: center;
    line-height: 21px;
}
.rating-overview-box .star-rating i {
    color:#ff9500;
}
/*----------- ED View Box ---------------*/
.ed_view_box {
    display: block;
    position: relative;
    border-radius:0.5rem;
    background: #ffffff;
    padding: 12px;
	margin-bottom:30px;
}
.ed_view_box.border {
  border: 1px solid #e7ecf5;  
}
.ed_view_box .property_video.sm {
    margin-bottom: 1rem;
}
.ed_view_price, .ed_view_features, .ed_view_link{
    display: block;
    margin-bottom: 1rem;
    padding-left: 10px;
}
.ed_view_price span {
    font-size: 13px;
}
.ed_view_features span {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    display: inline-block;
    color: #485067;
}
.ed_view_features ul {
    margin: 0;
    padding: 0;
}
.ed_view_features ul li {
    margin-bottom:10px;
    font-size:14px;
    position: relative;
    padding-left:22px;
}
.ed_view_features ul li i {
    width: 15px;
    height: 15px;
    background: rgba(0, 150, 136,0.1);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top:1px;
    font-size: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #009688;
}
.ed_view_link .enroll-btn {
    width: 80%;
    margin: 1.5rem auto 0;
    display: flex;
    height: 54px;
	font-weight:600;
    border-radius: 50px;
    color: #ffffff !important;
    align-items: center;
    justify-content: center;
}
.ed_view_link .enroll-btn i{
    margin-left:1rem
}
.ed_view_box.style_2 {
    padding: 0 0 12px;
}
.ed_author {
    padding:15px 20px;
    border-bottom: 1px solid #e6ebf5;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.ed_author .ed_author_thumb {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    overflow: hidden;
}
.ed_author_box h4 {
    font-size: 15px;
    margin-bottom: 0px;
	color: #24376f;
    line-height: 1.4;
}
.ed_author_box span {
    font-size: 13px;
}
.ed_view_box.style_3 {
    padding: 0 0 0;
	overflow:hidden;
    border-radius: 0.5rem;
}
.ed_view_box .property_video .thumb, .ed_view_box .property_video:before{
    border-radius:6px;
}
.ed_view_box.style_3 .property_video .thumb, .ed_view_box.style_3 .property_video:before{
    border-radius: 0;
}
.ed_view_box.style_3 .ed_view_features.half_list ul {
    display: flex;
    align-items: center;
    width: 100%;
	flex-wrap: wrap;
}
.ed_view_box.style_3 .ed_view_features ul li {
    margin-bottom:15px;
	margin-top:5px;
    font-size: 14px;
    position: relative;
    padding-left: 33px;
    display: flex;
    align-items: center;
    flex: 0 0 50%;
}
.ed_view_box.style_3 .ed_view_features ul li i {
    width: 24px;
    height: 24px;
    font-size: 10px;
}
.ed_view_box.style_3 .ed_view_features ul li i {
    background: #e9eef9;
    color: #707fab;
}
/*------------ Arrow Slide Style ---------------*/
.singles_items {
    padding: 0 10px;
}
.slick-next, .slick-prev {
    top: -35px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: all ease .4s;
    border: 1px solid #f0f3fb;
}
.slick-prev {
    right: 55px;
    left: auto;
}
.slick-next:before, .slick-prev:before {
    font-family: slick;
    font-size: 15px;
    line-height: 1;
    opacity: 1;
    color: #515c7b;
    font-family: themify;
    content: "\e649";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before {
    content: "\e64a";
}

/*----------- Arrow Middle -------------*/
.arrow_middle .slick-next, .arrow_middle .slick-prev {
    top:50%;
    right: 15px;
    width: 40px;
    height: 40px;
	z-index:1;
	opacity:0;
	transition:all ease 0.4s;
	background:#ffffff !important;
    border-radius:50%;
}
.arrow_middle .slick-prev {
    right: 0;
    left: 15px;
}
.arrow_middle:hover .slick-next, .arrow_middle:hover .slick-prev, .arrow_middle:focus .slick-next, .arrow_middle:focus .slick-prev{
	opacity:1;
}
.slick-dots li button:before {
    font-family: slick;
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 2px;
    content: '';
    text-align: center;
    opacity:.55;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background:#23b39f;
    border-radius: 50px;
}
.slick-dots li button:focus:before, .slick-dots li button:hover:before {
    opacity: 1;
	background:#da0b4e;
}
.slick-dots li.slick-active button:before{
	opacity: 1;
	background:#da0b4e;
}


/*----------------------- Slide Featured Course In Tab -----------------*/
.nav-tabs.side-cates {
    border-bottom: 1px solid #dde2f1;
}
.side-cates .nav-link {
    padding: 10px 15px !important;
}
.side-cates .nav-link {
    padding: 10px 15px !important;
    border: none !important;
    background: transparent !important;
    color: #5e6c92;
}
.nav-tabs.side-cates  .nav-item.show .nav-link, .nav-tabs.side-cates  .nav-link.active {
    color: #da0b4e;
}
a.btn-br-more {
    background: transparent;
    border: 1px solid #c8cedc;
    padding: 12px 20px;
    border-radius: 4px;
    background: #ffffff;
}
/*----------- Course category overlay ------------*/
.course_overlay_cat {
    border: none;
    height: 196px;
	margin-bottom:25px;
    border-radius: 0.2rem;
    overflow: hidden;
}
.course_overlay_cat .course_overlay_cat_thumb{
    width: 100%;
    padding: 0px;
    display: inline-block;
    height: 100%;
}
.course_overlay_cat_thumb a {
    display: inline-block;
    position: relative;
}
.course_overlay_cat_thumb a:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #1b2338;
    transition: 0.4s all ease;
    opacity: 0.8;
    border-radius: 0.4rem;
}
.course_overlay_cat_thumb img {
    height: 100%;
    max-height:196px;
    object-fit: cover;
}
.course_overlay_cat_caption{
    width: 100%;
    position: absolute;
    bottom:40px;
    margin: 0 auto;
    left:30px;
}

.course_overlay_cat_caption h4 {
    margin: 0;
    color: #ffffff;
    font-size: 17px;
    line-height: 1;
    letter-spacing: 1px;
}
.course_overlay_cat_caption h4 a {
    color: #ffffff !important;
}
.course_overlay_cat_caption span {
    font-size: 12px;
    color:#ffffff;
    margin-top:0px;
}

/*-------------------------------------------------------
	Education Common Page & Elements
------------------------------------------------------*/
#back2Top {
    width: 40px;
    line-height: 40px;
    overflow: hidden;
    z-index: 999;
    display: none;
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 20px;
    text-align: center;
    font-size: 15px;
    border-radius: 4px;
    text-decoration: none;
    background: #333c56;
    color: #fff;
}
/*------------- Accordion ---------------*/
.collapsible-link::before {
  content: '';
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: '';
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}

.collapsible-link[aria-expanded='true']::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded='true']::before {
  transform: rotate(180deg);
}
.card-header {
    position: relative;
}
.card {
    border-color: #e8edf7;
    margin-bottom: 10px;
}
.accordion_title {
    text-transform: capitalize;
    font-size: 16px;
}
/*------------- Page Title -------------*/
.page-title {
    text-align: center;
}
.page-title .breadcrumb-title {
    margin: 0;
}
.page-title .breadcrumb {
    box-shadow: none;
    text-align: center;
    margin: 0 auto;
    width: auto;
    display: table;
}
.page_sidebar {
    position: relative;
    width:90%;
	margin-bottom:30px;
    border: 2px solid #e6ebf1;
    border-radius: 0.4rem;
    padding: 2rem 1rem;
}
.ordering {
    text-align: right;
}
.btn-custom.dropdown-toggle {
    background: #ffffff;
    border: 2px solid #e6ebf1;
    border-radius: 4px;
    color: #647b9c !important;
}
/*--------------- Offscreen Overlay ------------*/
.filter-sidebar {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    transition: 0.5s;
    padding-top:20px;
	padding-bottom: 2rem;
    box-shadow: 0 0 20px 0 rgba(62,28,131,0.1);
}
.filter-sidebar .show-hide-sidebar {
    padding: 10px 30px 10px 20px;
    overflow-y: scroll;
    position: relative;
    width: calc(100% + 17px);
    min-height: 300px;
    display: block;
    height: 100%;
}
.filter-sidebar .ur-detail-wrap {
    padding: 0 1.4em 2em 1.4em;
}
.filter-sidebar .closebtn {
    position: relative;
    font-size: 36px;
    margin-left:00px;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    padding: 0;
    color: #2d4767;
}
.filt-head {
    display: flex;
    padding:0 1rem 1rem 1.5rem;
    flex-wrap: wrap;
    align-items: center;
}
.filt-head .filt-first {
    flex: 1;
	margin:0;
    font-size: 16px;
    font-weight: 500;
}
.filter_wraps {
    display: inline-flex;
    align-items: center;
    justify-content:flex-end;
    width: 100%;
}
div#main2 {
    margin-right: 5px;
}
#main2 .filter_open{
    cursor: pointer;
    left: auto;
    line-height:42px;
    overflow: hidden;
	letter-spacing:0;
	padding:0 18px;
    position:relative;
    right: 0;
    text-align: center;
    top: 38px;
    z-index: 1;
	top:0px;
	color:#ffffff;
	border-radius:4px;
    -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    -moz-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    -o-box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
    box-shadow: 0px 1px 4px 0px rgba(255, 90, 95, 0.3);
}
#main2 .filter_open.relative {
    position: relative;
    width: 100%;
    height: 50px;
	right: 0;
    display: inline-block;
}
.btn.arrow-btn.filter_open span {
    margin-left:28px;
}
.btn.arrow-btn.filter_open:before {
    right: 45px;
}
.btn.btn-loader {
    background: #ffffff;
    color: #da0b4e;
    border: 2px solid #c9cddc;
    border-radius: 4px;
    height: 54px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 40px;
    font-weight: 600;
	transition:all ease 0.4s;
}
.btn.btn-loader:hover, .btn.btn-loader:focus {
    background:#da0b4e;
    color:#ffffff;
    border: 2px solid #da0b4e;
}
/*---------------- Message notification --------------*/
.ground-list {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden!important;
    -ms-overflow-style: none;
}
.ground {
    display: -ms-flex-box;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}
.ground-list-single {
    -webkit-box-align: center;
    align-items: center;
}
.ground-list-single {
    padding: 16px 10px;
    border-bottom: 1px solid #eaeff5;
}
.ground>* {
    margin: 0 6px;
}
.ground-list-single a {
    position: relative;
}
.btn-circle-40 {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #606e88;
    border: 1px solid #eaeff5;
    background-color: #ffffff;
    display: inline-block;
    border-radius: 50%;
}
.ground-content {
    -ms-flex: 1;
    flex: 1;
}
.ground-content>* {
    margin-bottom: 0;
}

.card {
    margin-bottom: 30px;
    border: none;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0.25rem;
    overflow: hidden;
}
.card-header {
    background: #ffffff;
    border-color: #e6eaf1;
}
.card-header h6 {
    margin: 0;
    font-size: 14px;
}
.ground-content .small {
    display: block;
}
.ground-content h6 {
    font-size: 14px;
}

/*------------- Sign & Sign up Modal --------------*/
.modal-body {
    padding: 1rem 2rem;
}
.social-login ul {
    display: flex;
    align-items: center;
    width: 100%;
}
.social-login ul li:first-child {
    flex: 1;
}
.btn.pop-login {
    height: 56px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}
.modal-header-title {
    margin: 2rem auto;
    font-size: 40px;
    text-align: center;
}
span.mod-close {
    position: absolute;
    right: 15px;
    top: 15px;
    background: #f4f5f7;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    color: #2d3954 !important;
    cursor: pointer;
    z-index: 1;
}
.log_wrap {
    background: #ffffff;
    padding: 2rem 2rem;
    border-radius: 0.6rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.log_wrap .form-control {
    background: #f4f5f7;
}
.log_wrap h4 {
    text-align: center;
    margin-bottom:2rem;
    font-size: 35px;
}
/*------------ pricing Box ----------------*/
.packages_wrapping {
    text-align: center;
    width: 100%;
    background:rgba(249, 93, 2,0.001);
    border-radius: 1rem;
    margin-bottom: 25px;
    position: relative;
    border:1px solid rgba(249, 93, 2,0.2);
}
.packages_headers {
    padding: 3em 0 2em;
    width: 100%;
}
.packages_headers i {
    font-size: 3em;
    margin-bottom: 15px;
    display: table;
    margin: 0 auto 0.2em auto;
    color: #4c5f98;
}
.packages_headers .packages_pr_title{
    text-transform: uppercase;
    font-size: 18px;
    margin:0;
	font-weight:700;
}
.packages_headers .packages_price-subtitle {
    font-style: italic;
    font-size: 14px;
}
.packages_price {
    position: relative;
    display: table;
    margin:1rem auto;
    text-align: center;
}
.pr-value {
    font-size:4em;
    position: relative;
}
.recommended .pr-value {
    color: #fd5332;
}
.pr-value:before {
    content: "$";
    position: absolute;
    font-size: 2rem;
    top: -20px;
    left: -24px;
    color: #a6b0d0;
}
.packages_middlebody ul {
    text-align: center;
    margin: 0;
    padding: 0;
}
.packages_middlebody ul li {
    list-style: none;
    padding: 12px 0;
}
.packages_bottombody {
    display: table;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 2rem 0;
}
.packages_bottombody .btn-pricing {
    position: relative;
    display: table;
    text-align: center;
    margin: 0 auto;
    padding: 16px 50px;
    background:#da0b4e;
    border-radius: 4px;
    color: #ffffff;
    transition: all ease 0.4s;
    width: 80%;
}
.packages_bottombody .btn-pricing:hover, .packages_bottombody .btn-pricing:focus {
    background: #fc5231;
    color: #ffffff !important;
}
.packages_wrapping.recommended {
    background:#da0b4e;
}
.recommended .packages_headers i {
    color: #ffffff;
}
.recommended .packages_headers .packages_pr_title {
    color: #ffffff;
}
.recommended .packages_headers .packages_price-subtitle {
    color: rgba(255,255,255,0.5) !important;
}
.recommended .pr-value {
    color: #ffffff !important;
}
.recommended .pr-value:before {
    color: rgba(255,255,255,0.5) !important;
}
.packages_wrapping.recommended .packages_middlebody ul li {
    color: #ffffff;
}
.recommended .packages_bottombody .btn-pricing {
    background: #ffffff !important;
    color: #D70040 !important;
}
/*-------------- Sidebar -----------*/
.single_widgets {
    border:1px solid #e5e8f3;
	background:#ffffff;
    padding: 30px 28px 35px;
    margin: 0 0 40px;
}
.single_widgets.widget_search form {
    position: relative;
}
.single_widgets.widget_search form input[type="search"] {
    border-radius:4px;
    border:1px solid #ebedf5;
    background:#f9fafc;
    font-size: 12px;
    padding: 10px 70px 10px 30px;
    height:60px;
    width: 100%;
}
.single_widgets.widget_search form button {
    position: absolute;
    right: 5px;
    top: 5px;
    bottom: 0px;
    cursor: pointer;
    border-radius: 4px;
    background: #da0b4e;
    color: #ffffff;
    border: 0;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    width: 52px;
}
.single_widgets.widget_category ul {
    padding: 0;
    list-style: none;
}
.single_widgets.widget_category ul li {
    display: block;
    border-bottom: 1px solid #eaeaea;
    padding: 11px 0;
}
.single_widgets.widget_category ul li:first-child {
    border-top: none;
}
.single_widgets.widget_categories ul li a {
    display: block;
    overflow: hidden;
    letter-spacing: 0;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 24px;
}
.single_widgets.widget_category ul li a span {
    float: right;
}
.single_widgets.widget_thumb_post ul {
    padding: 0;
    list-style: none;
}
.single_widgets.widget_thumb_post ul li {
    display: block;
    overflow: hidden;
    margin: 0 0 15px;
}
.single_widgets.widget_thumb_post ul li .left {
    display: block;
    float: left;
    width: 80px;
}
.single_widgets.widget_thumb_post ul li .right {
    display: block;
    overflow: hidden;
}
.single_widgets.widget_thumb_post ul li .right .feed-title {
    display: block;
    overflow: hidden;
    letter-spacing: 0;
    font-weight:600;
    font-size:17px;
    line-height:22px;
    margin: 0 0 7px;
    padding-left: 20px;
}
.single_widgets.widget_thumb_post ul li .right .post-date {
    color: #6e7c90;
    display: block;
    overflow: hidden;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;
}
.single_widgets.widget_thumb_post ul li .right .post-date i {
    margin-right:5px;
}
.single_widgets.widget_thumb_post ul li .left img {
    width: 100%;
	border-radius:3px;
}
.single_widgets.widget_tags ul {
    padding: 0;
    list-style: none;
}
.single_widgets.widget_tags ul li {
    display: inline-block;
    margin-bottom:5px;
}
.single_widgets.widget_tags ul li a {
    border: 1px solid #eaeaea;
    letter-spacing: 0;
    font-size: 14px;
    display: block;
    padding: 9px 25px;
}
.single_widgets.widget_tags ul li a:hover, .single_widgets.widget_tags ul li a:focus {
    color: #ffffff;
    background:#da0b4e;
    border-color:#da0b4e;
}

/*-------------- Contact Page ---------------*/
.prc_wrap{
    display: block;
    width: 100%;
    background: #ffffff;
    padding: 0;
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid #e6eaf3;
}
.prc_wrap:last-child {
    margin-bottom: 0px;
}
.prc_wrap_header {
    display: table;
    width: 100%;
    padding: 0.7rem 1rem 0.7rem 2rem;
    border-bottom: 1px solid #e7eff9;
}
.prc_wrap_header h4 {
    margin: 0;
    position: relative;
    font-size: 16px;
}
.prc_wrap-body {
    line-height: 1.7;
    padding: 1rem 2rem;
    display: inline-block;
    width: 100%;
}
.contact-info{
	margin-left: 1rem;
}
.cn-info-detail{
    position: relative;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.cn-info-icon {
    float: left;
    display: inline-block;
    width: 50px;
    height: 50px;
}
.cn-info-icon i {
    font-size: 35px;
    color: #fd5332;
}
.cn-info-title {
    font-size:15px;
    margin-bottom: 2px;
}

/*---------- creative Tabs -------------*/
.customize-tab.tabs_creative{
    margin-bottom: 1rem;
	border-bottom: none;
}
.customize-tab.tabs_creative .nav-link {
    font-weight: 600;
    display: block;
    padding: .8rem 1.5rem;
    background: #edf0f5;
    border-radius: 4px;
    border: 1px solid #edf0f5 !important;
    margin-right: 0.5rem;
}
.tabs_content_creative .card-header {
    background: #f4f5f7;
    border-color: #e6eaf1;
}
.tabs_content_creative .btn.btn-link {
    padding: 0;
	text-decoration:none;
}
.custom_accordion .card{
    box-shadow: none;
    background: transparent;
    border: none;
}
.custom_accordion .card .card-header {
    background: #f4f5f7;
    margin-bottom: 0.5rem;
    padding: .95rem 1.25rem;
    border: none;
    border-radius: 4px;
}
.custom_accordion .card .card-header .card-title {
    margin: 0;
}

/*--------- Newsletter ---------------------*/
.newsletter.inverse-theme h2, .newsletter.inverse-theme p {
    color: #ffffff;
}
.sup-form {
    margin-top: 30px;
    display: block;
    position: relative;
}
.sup-form .form-control {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    padding: 20px 20px 18px;
    border-radius: 2px;
    height: 70px;
}
.sup-form .btn {
    bottom: 5px;
    position: absolute;
    right: 4px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 14px 25px 14px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    white-space: normal;
    width: auto;
    border-width: 0;
    cursor: pointer;
    height: 60px;
    border-radius: 2px;
}
/*------------- Brand --------------*/
.single_brands img {
    transition: all ease .4s;
    max-width: 130px;
    margin: 0 auto;
    display: table;
    opacity: .7;
}
.btn.btn-browse-btn {
    color: #ffffff;
    background:#ffffff;
    border-radius: 4px;
    height: 54px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
}
/*------------ Tab Style ------------*/
.tab-content>.tab-pane {
    padding:0 0 1rem;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #da0b4e;
    border-color: #da0b4e;
}
.nav-link {
	font-weight:600;
    display: block;
    padding: .8rem 1.5rem;
}
.nav-pills .nav-link {
    border-radius: .25rem;
    background: #f0f3f7;
    margin-right: 0.5rem;
}
.nav-pills.light .nav-link {
    background: #ffffff;
}
.nav-pills .nav-link.active, .show>.nav-pills .nav-link {
    color:#ffffff;
    background-color:#da0b4e;
}

/*------------- Cource Circullum------------*/
.circullum .card-body {
    padding-top: 0;
}
ul.lectures_lists {
    padding: 0;
    margin: 0;
    border-radius:2px;
    overflow: hidden;
}
ul.lectures_lists li {
    padding:17px 15px;
    background:#f1f4fb;
	color:#24394e;
    border-bottom: 1px solid #e5e8ef;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
	cursor: pointer;
}
.edu_wraper ul.lectures_lists li {
    background: #ffffff;
}
ul.lectures_lists li:last-child{
    border-bottom:none;
}
.lectures_lists_title {
    margin-right: 2rem;
    font-weight: 400;
    font-size: 14px;
	color:#647b9c;
}
.lectures_lists_title i{
    margin-right:5px;
}
ul.lectures_lists li:before {
    content: "\e63d";
    width: 30px;
    height: 30px;
    font-family: "themify";
    background: rgba(76, 175, 80,0.12);
    right: 15px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4caf50;
}
ul.lectures_lists li.unview:before {
    content:"\e62b";
    width: 30px;
    height: 30px;
    font-family: "themify";
    background: #d7dfef;
    right: 15px;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #50638a;
}

/*------------- testimonials ----------------*/
.client-detail-box {
    display: table;
    width: 100%;
    margin: 0 0 5px 0;
	text-align: center;
}
.client-detail-box .pic img {
    max-width: 100px;
    border-radius: 50px;
    border: 6px solid #ebedf1;
    margin:0.5rem auto;
}
.testimonial-title {
    font-size:18px;
	margin-bottom:0px;
}
span.post {
    font-size: 14px;
}
p.description {
    font-size: 18px;
	text-align:center;
    font-family: 'Lora', serif;
    font-style: italic;
}

/*----------------------------------------------
	Blog Styles
--------------------------------------------*/
/*------------- Blog Style ----------------*/
.articles_grid_style {
    position: relative;
    background: transparent;
    padding: 0 0 1rem;
    margin-bottom:30px;
	border-radius: 0.4rem;
    overflow: hidden;
    transition: all ease .4s;
	 box-shadow: 0px 0px 20px rgba(0,0,0,.075);
    -webkit-box-shadow: 0px 0px 20px rgba(0,0,0,.075);
    width: 100%;
}
.articles_grid_style {
    position: relative;
    background: transparent;
    padding: 0 0 1rem;
    margin-bottom: 25px;
    transition: all ease .4s;
    width: 100%;
}
.articles_grid_thumb img {
    max-width: 100%;
}
.articles_grid_caption {
    padding:1rem 1rem 00;
}
.articles_grid_caption h4 {
    font-size: 17px;
}
.articles_grid_author {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}
.articles_grid_author {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
}
.articles_grid_author .articles_grid_author_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
}
.articles_grid_author .articles_grid_author_img img {
    border-radius: 50%;
}
.articles_grid_author h4 {
    font-size: 14px;
    font-weight: 400;
    color: #7182a7;
}
/*--------- Blog Detail Page -----------*/
.single_article_wrap {
    display: block;
    width: 100%;
    background: #ffffff;
    padding:20px;
    border-radius: 4px;
    margin-bottom: 30px;
    border:1px solid #e5e8f3;
}
.article_featured_image {
    display: block;
    margin-bottom: 1.5rem;
}
.article_featured_image img {
    border-radius:6px;
}
.single_article_wrap .article_body_wrap .article_middle_info {
    padding: 0;
    list-style: none;
}
.single_article_wrap .article_body_wrap .article_middle_info li {
    display: inline-block;
    margin-right: 19px;
}
.single_article_wrap .article_body_wrap .article_middle_info li a {
    color: #6e7c90;
    display: block;
    letter-spacing: 0;
    line-height: 18px;
}
.single_article_wrap .article_body_wrap .article_middle_info li a .icons {
    margin-right: 10px;
}
.single_article_wrap .article_body_wrap .post-title {
    display: block;
    font-weight: 700;
    font-size:34px;
	line-height:1.4;
    margin: 15px 0 0;
}
.single_article_wrap .article_body_wrap p, .single_article_wrap .article_body_wrap .text {
    color: #6e7c90;
    font-size:16px;
    margin: 35px 0 0;
}
.blog-page .article_detail_wrapss blockquote {
    background:#f7f9fc;
    padding: 28px 28px 28px 100px;
    margin: 50px 0;
	position: relative;
}
.blog-page .article_detail_wrapss blockquote .icon {
    color:#da0b4e;
    position: absolute;
    font-size: 28px;
    line-height: 28px;
    left:35px;
    top:42%;
}
.blog-page .article_detail_wrapss blockquote .name {
    color: #000c35;
    margin: 12px 0 0;
}
.blog-page .article_detail_wrapss .article_bottom_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 43px 0 0;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags .pbm-title {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 20px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags .list {
    padding: 0;
    list-style: none;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags .list li {
    display: inline-block;
    margin-bottom: 10px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags .list li a {
    border: 1px solid #e4e4e6;
    letter-spacing: 0;
    display: block;
    padding:9px 20px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-tags .list li a:hover {
    color: #ffffff;
    background:#da0b4e;
    border-color:#da0b4e;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .list {
    padding: 0;
    list-style: none;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .list li {
    display: inline-block;
    margin-right: 22px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .list li:last-child{
	margin-right:0;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    text-align: right;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .pbm-title{
	font-size:18px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .list li a {
    color: #8c96b5;
    display: block;
    font-size: 16px;
    line-height: 22px;
}
.blog-page .article_detail_wrapss .article_bottom_info .post-share .list li a:hover {
    color: #ef4060;
}
.single_article_pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    border-top: 1px solid #e4e4e6;
    padding: 48px 0 0;
    margin: 40px 0 0;
}
.single_article_pagination .prev-post {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
}
.single_article_pagination .next-post {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    text-align: right;
}
.single_article_pagination .next-post .title,
.single_article_pagination .prev-post .title{
	font-size:20px;
}
.single_article_pagination .article_pagination_center_grid {
    position: absolute;
    left: 50%;
    top:60%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.single_article_pagination .article_pagination_center_grid a {
    font-size: 2.5rem;
    color: #da0b4e;
}
.single_article_pagination .prev-post a, .single_article_pagination .next-post a {
    display: inline-block;
    overflow: hidden;
    padding: 14px 25px;
    height: 50px;
    border: 4px !important;
    color: #ffffff !important;
    overflow: hidden;
    text-transform: capitalize !important;
}
.single_article_pagination .prev-post a span, .single_article_pagination .next-post a span{
margin:0;
}
.single_article_pagination .prev-post a .title-with-link .title {
    color: #000c35;
    line-height: 28px;
}

/*-------- Comment ---------*/
.blog-page .article_detail_wrapss .comment-area .all-comments .comments-title {
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 35px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments .comment-list ul {
    padding: 0;
    list-style: none;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article {
    overflow: hidden;
    margin: 0 0 50px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .article_comments_thumb {
    float: left;
    width: 100px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .article_comments_thumb img{
	max-width:100px;
	border-radius:50%;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details {
    overflow: hidden;
    padding-left: 30px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .author-name {
    font-size:18px;
    line-height: 28px;
	margin-bottom:2px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .author-name .selected {
    font-size: 12px;
    color: #cacfef;
    margin-left: 4px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-left-meta .comment-date {
    color: #ef4060;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    font-weight: 500;
    margin: 7px 0 0;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-reply {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30%;
    flex: 1 1 30%;
    text-align: right;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-reply .reply {
    color: #6e7c90;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-meta .comment-reply .reply .icon {
    margin-right: 8px;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-text {
    margin: 19px 0 0;
}
.blog-page .article_detail_wrapss .comment-area .all-comments article .comment-details .comment-text p {
    margin: 0;
}
.blog-page .article_detail_wrapss .comment-area .all-comments .comment-list ul li ul {
    padding-left: 40px;
    list-style: none;
}
.article_posts_thumb {
    text-align: center;
}
.article_posts_thumb span.img {
    width: 120px;
    overflow: hidden;
    border-radius: 50%;
    height: 120px;
    border: 4px solid #e4eaf3;
    display: table;
    margin: 0 auto 1rem auto;
}
span.img img {
    border-radius: 50%;
    max-width: 120px;
}
h3.pa-name {
    font-size: 18px;
	margin-bottom:5px;
}
.social-links {
    padding: 0;
    margin: 0 0 1rem 0;
}
.social-links {
    padding: 0;
    margin: 0 0 1rem 0;
}
.social-links li{
	display:inline-block;
	padding:2px 10px;
}
.social-links li a {
    color:#8c96b5;
    font-size: 16px;
}
.social-links li a:hover,
.social-links li a:focus{
	color:#da0b4e;
}

/*----------------------------------------------
	Shop Page Designs
--------------------------------------------*/
/*----------- Shop Grid -------------*/
.shop_grid {
    padding: 2rem 1rem;
    text-align: center;
    width: 100%;
	margin-bottom:30px;
    display: block;
    position: relative;
    border-radius: 0.4rem;
    border: 2px solid #e5ecf5;
}
.shop_grid_thumb {
    max-height: 180px;
    display: table;
    margin: 1rem auto 2rem;
}
.shop_grid_thumb img {
    max-width: 100%;
    max-height: 180px;
}
.shop_grid_caption {
    display: block;
    text-align: center;
}
.shop_grid_caption .sg_rate_title {
    font-size: 17px;
	line-height:1.2;
    margin-bottom:0px;
}
.shop_grid_caption .sg_rate {
    font-size: 20px;
    font-weight: 800;
}
.shop_grid_rate i {
    font-size: 10px;
    margin: 0 1px;
    color: #c1cde4;
}
.shop_grid_rate .filled {
    color: #FF9800;
}
.shop_grid_action {
    position: absolute;
    top:50%;
	left:50%;
    width: 100%;
    text-align: center;
    transition: all ease 0.4s;
	transform:translate(-50%, -50%);
    opacity:0;
}
.shop_grid_action .btn-shop {
    width:35px;
    height:35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    padding: 0;
    border-radius: 50%;
    margin: 0 5px;
	color:#8ca1bf;
    border: 1px solid #e3e6f1;
    box-shadow: 0px 0px 0px 3px rgba(255,255,255,1);
}
.shop_grid:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #051321;
    opacity:0;
	transition: all ease 0.4s;
    border-radius: 0.4rem;
}
.shop_grid:hover:before, .shop_grid:focus:before{
	opacity: 0.7;
}
.shop_grid:hover .shop_grid_action, .shop_grid:focus .shop_grid_action{
	opacity:1;
}
.shop_status.hot {
    background:#f33374;
    display: inline-block;
    padding: 4px 15px;
    border-radius: 5px;
    font-size: 13px;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #ffffff;
}
.shop_status.sell, .shop_status.new {
    background:#42ad63;
	display: inline-block;
    padding: 4px 15px;
    border-radius: 5px;
    font-size: 13px;
    position: absolute;
    top: 15px;
    left: 15px;
    color: #ffffff;
}

/*------------ Quick View ------------*/
.woocommerce.single_product.quick_view_wrap {
    width: 100%;
    display: flex;
    align-items: center;
}
.single_product.quick_view_wrap .woo-single-images{
    width: 50%;
}
.feature-style-single img {
    width: 100%;
}
.single_product.quick_view_wrap .entry-summary{
    width: 50%;
    margin: 0;
    padding: 0;
}
.single_product.quick_view_wrap .entry-summary>.woo_inner {
    max-width: calc(100% - 40px);
    padding-left: 70px;
}
.woo_product_title {
    font-size: 22px;
    margin: 0;
    line-height: 1.4;
}
.woo_price_rate {
    display: flex;
    align-items: center;
    width: 100%;
}
.woo_rating {
    margin-right: 1rem;
}
.woo_rating i {
    font-size: 10px;
    margin: 0 1px;
    color: #c1cde4;
}
.woo_rating .filled {
    color: #FF9800;
}
.woo_short_desc {
    margin-top: 1.5rem;
}
.quantity-button-wrapper {
    margin: 1.5rem 0;
}
.quantity-button-wrapper {
    display: flex;
    align-items: center;
}
.quantity-button-wrapper label {
    font-weight: 800;
    margin-right: 1rem;
    color: #454d67;
}
.woo_buttons {
    display: flex;
    align-items: center;
    width: 100%;
}
.woo_buttons .btn.btn-theme2{
    height: 54px;
    border-radius: 4px;
    background: #da0b4e;
}
.woo_buttons .woo_btn_light {
    height: 54px;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    background: #e3e7ef;
    color: #4f5a6f;
    border-radius: 4px;
    padding: 0 20px;
    display: flex;
    transition: all ease 0.4s;
}
.woo_buttons .woo_btn_light:hover, .woo_buttons .woo_btn_light:focus {
    color:#ffffff;
	background:#da0b4e;
}
.woo_inner .shop_status.sell, .woo_inner .shop_status.new {
    left:25px;
}
.woo_inner .shop_status.hot{
    left:25px;
}
ul.woo_info {
    display: inline-block;
    margin: 0;
    width: 100%;
}
ul.woo_info li {
    display: inline-block;
    margin-right: 1rem;
}
.tag_cloud {
    display: inline-block;
}
.tag_cloud a {
    display: block;
    float: left;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 1.5;
    padding: 7px 15px 6px;
    border-radius: 3px;
    background: #e7eaf1;
    color: #919baf;
    border: 0;
    margin: 5px;
    overflow: hidden;
}
.tag_cloud a:hover, .tag_cloud a:focus {
    background:#da0b4e;
    color:#ffffff !important;
}
.product_item{
    display: flex;
    align-items: center;
}
.product_item .thumbnail {
    line-height: 0;
    flex-shrink: 0;
}
.product_item .thumbnail img {
    float: left;
    margin-right: 20px;
    margin-left: 0;
    width: 100px;
}
.product_item .product-title {
    display: block;
    margin-top: 12px;
    font-size: 14px;
    font-weight:600;
    line-height: 1.44;
	margin-bottom:0;
}
.product_item .product-title a {
    display: inline-block;
    color: inherit;
    font-weight: inherit;
}
.product_item .price {
    display: block;
    font-size: 14px;
    margin: 7px 0 0;
}
.price_ver {
    font-weight:800;
	color:#da0b4e;
}
.price_ver del {
    margin-left: 1rem;
    color:#9eadc7;
    font-size: 12px;
}
/*------------ Wishlist & Add To cart ---------------*/
a.remove_cart {
    height:32px;
    width:32px;
    display: flex;
    border-radius: 50%;
    background: #e5e7f1;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
.tb_course_thumb {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.tb_course_thumb img {
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}
span.wish_price {
    font-weight: 800;
    font-size: 18px;
}
span.tb_date {
    display: block;
    font-size: 13px;
    font-weight: 400;
}
.btn.btn-add_cart {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    background: #06a798;
    color: #ffffff !important;
    border-radius: 4px;
    padding: 10px 25px;
}
.checkout_coupon {
    display: flex;
    align-items: center;
    width: 100%;
}
.checkout_coupon.checkout .form-control {
    width: auto;
}
.checkout_coupon_flex {
    flex: 1;
}
.checkout .btn.btn-theme2 {
    height: 54px;
    border-radius: 4px;
    background:#da0b4e;
}
.checkout .btn.empty_btn{
    height: 54px;
    border-radius: 4px;
    background:#da0b4e;
}
.cart_totals table.table tr th {
    padding-left: 0;
}
input.form-control.qty {
    background: #f4f5f7;
    max-width: 110px;
}
.btn.btn-remove {
    background: #ffffff;
    color: #40527b;
    font-weight: 600;
    border: 2px solid #d2daec;
    transition: all ease 0.4s;
    border-radius: 4px;
}
.btn.btn-remove:hover, .btn.btn-remove:focus{
	color:#ffffff;
	background:#da0b4e;
	border-color:#da0b4e;
}
.cart_totals.checkout {
    padding: 1.5rem;
    background: #ffffff;
    box-shadow: none;
    border-radius: 0.5rem;
    border: 1px solid #e8ecf7;
}
.cart_totals.checkout h4 {
    font-size: 17px;
}
ul.cart_list li {
    padding: 7px 0;
    display: block;
    width: 100%;
}
ul.cart_list li strong {
    float: right;
    color: #5c698e;
}
.flex_cart {
    display: flex;
    align-items: center;
    margin: 1rem 0 4rem;
    border-top: 1px dashed #dfe6ec;
    padding-top: 15px;
}
.flex_cart_1 {
    flex: 1;
}
.flex_cart_1 {
    font-size: 18px;
    font-weight: 600;
    color:#da0b4e;
}
.flex_cart_2{
    font-size: 18px;
    font-weight: 600;
    color: #0d93b1;
}
.btn.checkout_btn {
    height: 54px;
    width: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
	transition:all ease 0.4s;
    background:#e9ecf1;
    color: #4b577b;
    font-weight: 600;
}
.btn.checkout_btn:hover, .btn.checkout_btn:focus {
    color:#ffffff;
	background:#da0b4e;
}
.light_form .form-control, .light_form span.select2-selection.select2-selection--single {
    background: #f4f5f7;
}
.choose_payment_mt {
    width: 100%;
    text-align: center;
    padding: 1rem;
	cursor:pointer;
	position:relative;
    border: 1px solid #e8ecf7;
    border-radius: 0.4rem;
    margin-bottom: 30px;
}
.choose_payment_mt img {
    max-width: 60px;
}
.choose_payment_mt.active:before {
    content: "\e64c";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 18px;
    height: 18px;
    display: flex;
    background: #4CAF50;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    font-family: themify;
    border-radius: 50%;
}

/*==================================================
	Dashboard Design 
===================================================*/
/*-------------- Common Design ------------------*/
.dashboard_container {
    background: #ffffff;
    border-radius: 0.4rem;
    padding: 0;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
    position: relative;
    display: block;
    margin-bottom: 30px;
    width: 100%;
}
.dashboard_container_header {
    display: flex;
    align-items: center;
    width: 100%;
    padding:15px 20px;
    border-bottom: 1px solid #f2f7fd;
}

.dashboard_fl_1 {
    flex: 1;
}
.dashboard_fl_1 h4 {
    margin: 0;
	font-weight:400;
    font-size: 17px;
}
.dashboard_container_body {
    padding:0;
}
.dashboard_container_header .form-inline .form-control, .form-inline.addons .form-control{
	border-right:none;
	width: auto;
	border-radius:5px 0px 0px 5px;
}
.dashboard_container_header .form-inline button , .form-inline.addons button {
    background-color: #ffffff;
    border:1px solid #e6eaf3;
    border-left: none;
    border-radius: 0 5px 5px 0;
    height:54px;
	color:#a8b0c5 !important;
	display: flex;
    align-items: center;
	padding:0 15px 0 0px;
    justify-content: center;
}
.form-control:focus {
    border-color: #e6eaf3;
}
/*------------------ Course Design -----------------*/
.dashboard_single_course {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding:20px 30px 20px;
    border-bottom: 1px solid #dadfea;
}
.dashboard_single_course_thumb {
    width: 280px;
    height:220px;
    border-radius: 0.3rem;
	position:relative;
    overflow: hidden;
    transition: all ease 0.4s;
}
.dashboard_single_course_thumb img {
    height: 100%;
    object-fit: cover;
}
.dashboard_single_course_thumb:before {
    background: #0f1621;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    position: absolute;
    content: "";
    border-radius: 0.3rem;
	transition: all ease 0.4s;
    opacity:0;
}
.dashboard_action {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    top: 45%;
	opacity:0;
    transition: all ease 0.4s;
}
.dashboard_action .btn.btn-ect {
    padding: 7px 25px;
    background: rgba(255,255,255,0.2);
    margin: 4px;
	color:#ffffff;
    border-radius: 50px;
}
.dashboard_single_course_caption {
    width: 100%;
    flex: 1;
	padding-left: 20px;
    position: relative;
}
.dashboard_single_course_head {
    display: flex;
    align-items: center;
    width: 100%;
}
.dashboard_single_course_head_flex {
    flex: 1;
}
.dashboard_course_title {
    font-size: 18px;
    margin-bottom: 2px;
}
.dashboard_rats {
    display: flex;
    align-items: center;
}
.dashboard_rating {
    margin-right: 10px;
}
.dashboard_rating i {
    font-size: 12px;
}
.dashboard_rating i.filled {
    color: #FF9800;
}
.dashboard_single_course_des {
    margin-top: 1rem;
}
.progress-bar {
    height: 5px;
}
.dashboard_single_course_progress {
    display: flex;
    align-items: center;
    width: 100%;
}
.dashboard_action.circle .btn.btn-ect {
    padding: 0;
    background: rgba(255,255,255,0.2);
    margin: 4px;
    color: #ffffff;
    border-radius: 50px;
    width: 44px;
    height: 44px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 3px rgba(255,255,255,0.2);
}
.dashboard_action.circle .btn.edit{
	background:#2196f3;
}
.dashboard_action.circle .btn.view{
	background:#8bc34a;
}
.dashboard_action.circle .btn.delete{
	background:#da0b4e;
}
.dashboard_single_course_progress_1 {
    flex: 1;
    padding-right: 8rem;
}
.dashboard_single_course:hover .dashboard_action , .dashboard_single_course:focus .dashboard_action {
	opacity:1;
}
.dashboard_single_course:hover .dashboard_single_course_thumb:before, .dashboard_single_course:focus .dashboard_single_course_thumb:before{
	opacity:0.7;
}

span.payment_status {
    padding: 5px 15px;
    border-radius: 3px;
	font-size:13px;
    background: #f4f5f7;
}
.dash_action_link {
    display: inline-block;
    width: 100%;
}
.dash_action_link a {
    background: #f4f5f7;
    padding: 5px 15px;
	font-size:13px;
    display: inline-block;
}
span.payment_status.inprogress {
    background: rgba(255, 152, 0,0.1);
	color: #FF9800;
}
span.payment_status.complete {
    color: #459e49;
    background: rgba(69, 158, 73,0.1);
}
span.payment_status.pending {
    color: #2196f3;
    background: rgba(33, 150, 243,0.1);
}
span.payment_status.hold {
    background: rgba(166, 97, 218,0.1);
    color: #a661da;
}
span.payment_status.cancel {
    background: rgba(233, 30, 99,0.1);
    color: #e91e63;
}
.dash_action_link a.view {
    background: rgba(0, 150, 136,0.1);
    color: #009688;
}
.dash_action_link a.cancel {
    background: rgba(233, 30, 99,0.1);
    color: #e91e63;
}
.table tr th, .table tr td {
    border-color: #e3ecf5 !important;
    padding: 15px 20px;
}
/*------------ Dashboard Side --------------*/
/* .breadcrumb {
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #ffffff;
    border-radius: .25rem;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
}
.breadcrumb-item.active {
    color: #da0b4e;
} */

.dashboard-navbar, .dashboard-wraper {
    position: relative;
    width: 100%;
    padding: 2em;
	background:#1b2132;
    box-shadow:0px 1px 4px 0px #252c41;
    border-radius:0rem 0 0.2rem 0.2rem;
}
.d-user-avater {
    text-align: center;
    margin: 2em 0;
}
.d-user-avater img {
    max-width:120px;
    border-radius: 50%;
    margin-bottom: 8px;
}
.d-user-avater h4 {
    margin: 10px 0 3px 0;
	color:#ffffff;
    font-size:17px;
}
.d-navigation ul {
    padding: 0;
    margin: 0;
}
.d-navigation ul li {
    list-style: none;
    padding:0;
}

.d-navigation ul li a{
    padding:3px 0;
    color:#77829e;
	font-size:14px;
	 border-radius: 0.3rem;
	padding: 14px 15px;
	display: inline-block;
}
.d-navigation ul li.active>a {
    color: #ffffff !important;
    background: #da0b4e; 
}
.d-navigation ul li a i{
	margin-right:10px;
}
ul.nav.nav-second-level.collapse {
    display: none;
}
ul.nav.nav-second-level.collapse.show {
    display: block;
}
.nav-second-level li {
    display: block;
    width: 100%;
}
.d-navigation ul li a {
    width: 100%;
}
.nav-second-level li a {
    color:#787e94 !important;
}
.d-navigation ul li a span {
    font-size: 12px;
    float: right;
    top: 5px;
    position: relative;
}
.d-navigation ul li.active a span:before{
	content:"\e64b";
}

.dashboard_stats_wrap {
    display: inline-block;
    padding: 0;
    height: 160px;
    background-color: #444;
    color: #fff;
    border-radius:10px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    transition: .3s;
    cursor: default;
}
.dashboard_stats_wrap_content {
    position: absolute;
    left: 32px;
    top: 50%;
    transform: translateY(-50%);
}

.dashboard_stats_wrap_content h4 {
    font-size:35px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color:#22374e;
    letter-spacing: 1px;
}
.dashboard_stats_wrap_content span {
    font-size: 18px;
    margin-top: 4px;
    line-height: 24px;
    font-weight: 300;
    display: inline-block;
}
.dashboard_stats_wrap-icon {
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-40%);
    font-size: 80px;
}
.dashboard_stats_wrap-icon {
    font-size: 65px;
}
.dashboard_stats_wrap.widget-1 {
    background-color:rgba(27, 175, 101,0.1);
}
.dashboard_stats_wrap.widget-2 {
    background-color:rgba(243, 159, 0,0.1);
}
.dashboard_stats_wrap.widget-3 {
    background-color: rgba(0, 39, 88,0.1);
}
.dashboard_stats_wrap.widget-4 {
    background-color: rgba(206, 2, 75,0.1);
}
.dashboard_stats_wrap.widget-5 {
    background-color:rgba(166, 113, 206,0.1);
}
.dashboard_stats_wrap.widget-6 {
    background-color:rgba(58, 157, 236,0.1);
}
.widget-1 .dashboard_stats_wrap_content span, .widget-1 .dashboard_stats_wrap-icon {
	color:#1baf65;
}
.widget-2 .dashboard_stats_wrap_content span, .widget-2 .dashboard_stats_wrap-icon {
	color:#f39f00;
}
.widget-3 .dashboard_stats_wrap_content span, .widget-3 .dashboard_stats_wrap-icon {
	color:#002758;
}
.widget-4 .dashboard_stats_wrap_content span, .widget-4 .dashboard_stats_wrap-icon {
	color:#ce024b;
}
.widget-5 .dashboard_stats_wrap_content span, .widget-5 .dashboard_stats_wrap-icon {
	color:#a671ce;
}
.widget-6 .dashboard_stats_wrap_content span, .widget-6 .dashboard_stats_wrap-icon {
	color:#3a9dec;
}


/*-------------------------------------------------
  Footer Start   
--------------------------------------------------*/
/*---------- Call To Action -------------------*/
.call-to-act-wrap {
    padding: 50px 0;
}
.call-to-act {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
	color:#ffffff;
    align-items: center;
}
.call-to-act-head {
    flex: 1;
}
.call-to-act h3 {
    color:#ffffff;
	margin-bottom:5px;
}
.btn.btn-call-to-act {
    background: #ffffff;
    padding: 18px 40px;
    border-radius: 50px;
    color: #333640;
    font-weight: 600;
	box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.2);
	-webkit-box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.2);
}

/*---------- Small footer -------------------*/
footer{
	z-index:99;
	padding-top:60px;
	position:relative;
}
footer.dark-footer{
	background:#252c41;
}
.footer-logo {
    padding: 20px 0;
    display: inline-block;
    width: 100%;
    border-top: 1px solid #eaeef3;
    border-bottom: 1px solid #eaeef3;
}
footer.footer-small {
    padding: 50px 0 0 0;
}
img.img-footer {
    max-width: 150px;
    margin-bottom: 2rem;
}
.ft-itext {
    font-weight: 500;
	font-size:16px;
	text-align: center !important;
    margin: 10px;
}
.ft-itext i {
    margin-right:5px;
}
ul.social {
    margin: 0;
    padding: 0;
}
ul.social li {
    display:inline-block;
	list-style:none;
	margin:5px;
}
ul.social li a {
    width: 40px;
    height: 40px;
    display: inline-block;
    background: #ffffff;
    border-radius: 50%;
   -webkit-box-shadow: 0 0px 12px 0 rgba(71,119,244,0.2);
	box-shadow: 0 0px 12px 0 rgba(71,119,244,0.2);
    line-height: 40px;
    text-align: center;
}
.footer-add {
    color: #858b9f;
    font-size: 13px;
}
.ft-copyright {
    border-top: 1px solid #eaeef3;
    padding: 15px 0 12px 0;
}
.ft-copyright p{
	margin-bottom:0;
}
.footer-logo img {
    max-width: 185px;
}
.footer-widget {
    padding:0px 0 30px;
}
h4.widget-title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 17px;
}
.footer-widget ul {
    padding: 0;
    margin: 0;
}
.footer-widget ul li {
    list-style: none;
    margin-top:15px;
    display: block;
}
.footer-widget ul li a{
	color:#626a70;
}

footer.skin-dark-footer h4{
    color: #ffffff;
}
footer.skin-dark-footer .footer-widget ul li a, footer.skin-dark-footer, footer.skin-dark-footer a {
    color: #858b9f;
    transition: all ease 0.4s;
    font-size: 14px;
}
footer.skin-dark-footer .footer-widget ul li a:hover, footer.skin-dark-footer .footer-widget ul li a:focus{
    color:#ffffff;
}
.widget_media_image {
    margin-top: 3rem;
}
.footer-bottom {
    padding:20px 0;
	margin-top: 30px;
    border-top: 1px solid #e9edf3;
}
.skin-dark-footer .footer-bottom {
    background:#1b2132;
	border-top:none;
}
ul.footer-bottom-social {
    margin: 0;
    padding: 0;
}
ul.footer-bottom-social li {
    display: inline-block;
    list-style: none;
    margin-right: 17px;
	margin-top: 0;
}
ul.footer-bottom-social li a {
    color:#546e7a;
}
ul.footer-bottom-social li a:hover, ul.footer-bottom-social li a:focus{
	color:#007bff;
}
ul.footer-bottom-social li i {
    margin-right: 5px;
}
footer.skin-dark-footer ul.footer-bottom-social li a {
    color: #858b9f;
    font-size: 14px;
}
footer.skin-dark-footer ul.footer-bottom-social li a:hover, footer.skin-dark-footer ul.footer-bottom-social li a:focus{
	color:#ffffff;
	opacity:1;
}
.fw {
    margin-bottom: 18px;
    display: inline-block;
    position: relative;
	padding-left: 28px;
	width: 100%;
}
.fw:last-child {
    margin: 0;
}
.fw:before {
    font-family: 'themify';
    vertical-align: initial;
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
}
.fw.fw-location:before {
    content: "\e693";
}
.fw.fw-mail:before {
    content: "\e75a";
}
.fw.fw-call:before {
    content:"\e621";
}
.fw.fw-skype:before {
    content:"\e72d";
}
.fw.fw-web:before {
    content:"\e665";
}

.other-store-link {
    width: auto;
	margin-bottom:20px;
    display: inline-block;
}
.other-store-link:last-child{
	margin-bottom:0;
}
.other-store-app {
    width:250px;
    height:70px;
    border:1px solid #353d56;
    border-radius: 4px;
    padding: 0 14px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color:#ffffff;
}
.os-app-caps span {
    display: block;
    font-size: 13px;
    font-weight: 400;
	color:#858b9f;
}
.os-app-icon {
    margin-right: 13px;
}
.os-app-icon i {
    font-size: 32px;
}
.os-app-caps {
    font-size: 18px;
    font-weight: 600;
}

.f-newsletter {
    max-width: 510px;
    margin-top: 15px;
    display: block;
    position: relative;
}

.f-newsletter .form-control {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
	color:#7f8ca2;
    background-color: transparent;
    border: 2px solid #a5aebf;
    padding:18px 20px 18px;
    border-radius: 3px;
    height: 60px;
}
.skin-dark-footer .f-newsletter .form-control {
    border: 2px solid #9ea6ba;
}
.f-newsletter .btn {
    bottom: 4px;
    position: absolute;
    right: 4px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 18px 12px 14px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: normal;
    width: auto;
    border-width: 0;
    color:#9ea6ba;
    cursor: pointer;
    border-radius: 2px;
    height: 52px;
	background:transparent;
}
.skin-dark-footer .form-control::-webkit-input-placeholder{color:#9ea6ba;}
.skin-dark-footer .form-control:-ms-input-placeholder{color:#9ea6ba;}
.skin-dark-footer .form-control::placeholder{color:#9ea6ba;}

.light-footer .other-store-app {
    color:#4a5682;
	border: 1px solid #d9dde8;
}
/*------- Footer Top -------------*/
.foot-news {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.foot-news-first {
    padding-right: 2rem;
}






/*=========================================
	Responsiveness Designs
=========================================*/
@media (min-width: 576px) { 

}


@media (min-width: 768px) {
.banner-search .br-right {
	border-right: 1px solid #e6eaf3;
}
.tp-side-menu {
    display: none;
}
.banner-search .small-pad {
    padding-left:0px;
    padding-right:0px;
}
.big-header-capt {
    font-size: 50px;
    line-height: 1.4;
}
.style_1 .single_job_title, .style_2 .single_job_title{
    max-width: 80%;
}
.style_3 .single_job_title {
    max-width:90%;
}
}


@media (min-width: 992px) {
.banner-search .form-group {
    height:75px;
}
.vw-lg-50 {
    width: 50vw !important;
}
.page_title h2 {
    font-size: 60px;
    line-height: 1.2;
}
.details_single h2 {
    font-size: 22px;
}
.hide-92{
	display:none;
}	
p.description{
    line-height: 1.8;
    max-width: 850px;
    margin: 0 auto;
}
.overlio {
    margin-top: -120px;
}
}

@media (min-width: 1024px) {
.top_hm_2 {
	position: relative;
	top: -45px;
}
.page-title .breadcrumb-title {
   font-size: 52px;
}
.about_caption h4 {
    font-size: 40px;
}
.hide-24{
	display:none;
}
.testi_description {
    font-size: 22px;
	margin-bottom:2rem;
}
.single_testi_caption {
    padding-left: 5rem;
}
}

@media (min-width: 1200px) {
.hero_banner {
    min-height: 620px;
    height:92vh;
    padding: 0;
}
.hero_banner.full {
    height:100vh;
}
.big-header-capt {
    font-size:50px;
    line-height: 1.2;
}
.hero_banner.home-4 {
    border-radius: 0px 0 0 30%;
}
.elix_img_box {
    max-width: 430px;
    overflow: hidden;
    border-radius: 30% 2%;
    position: absolute;
	display:block;
    right: 40px;
    top: 80px;
}	
}



@media (max-width: 575.98px) {
.list_jobs_header {
  display: block;
}
.course_overlay_cat_thumb img {
    height: 100%;
    max-height: 196px;
    object-fit: cover;
    width: 100%;
}
.rating-bars-item {
    width: calc(100% - 30px);
}
h2.ed_title {
    font-size: 23px;
}
ul.edu_list.third li {
    flex: 0 0 100%;
}
ul.head_jobs_social li a {
    width: 40px;
    height: 40px;
    display: flex;
}
.customize-tab.tabs_creative .nav-link {
    padding: .8rem 1rem;
}
.viewer_detail_wraps {
    display: block;
}
.viewer_detail_thumb {
    margin-bottom: 1rem;
}
.single_instructor {
    display: block;
}
.single_instructor_caption {
    flex: auto;
    padding-left: 0;
    padding-top: 1rem;
}
.review-comments-avatar {
    position: absolute;
    top: 0;
    left: 0px;
    width: 50px;
    height: 50px;
}
.reviews-comments-item {
    padding: 0 0 30px 60px;
}

ul.head_jobs_social li {
    display: inline-block;
    margin-right: 5px;
}
#brand-slide {
    padding-top: 2rem;
}
.footer_widget {
    padding-top: 2.5rem;
}
.top-footer {
    padding: 3rem 0 0;
}
img.img-footer.small {
    max-width: 140px;
    margin-left: 0;
}

.featured_job_select {
    right: 10px;
}
.list_jobs_thumbs {
    margin-bottom: 0.5rem;
}
.lj_footer_last.style-2 ul li {
    margin-right: 4px;
    font-size: 12px;
    padding: 6px 10px;
}
.lj_footer_last.style-2 ul li i {
    margin-right: 4px;
}
.filter_box_first {
    display: block;
    margin-bottom: 10px;
}
.filter_box {
    display: block;
}
}

@media (max-width: 767px) {
.trips_wrap.colored .trips{
	border:none;
}	
.trips{
	padding-left:0;
	padding-right:0;
}
.list_jobs_footer {
	display: block;
}
.fs-left-map-box {
    float: none;
}
.course_overlay_cat_thumb img {
    height: 100%;
    max-height: 196px;
    object-fit: cover;
    width: 100%;
}
.ed_detail_wrap {
    padding-top: 1rem;
}
.woocommerce.single_product.quick_view_wrap {
    display: block;
}
.single_product.quick_view_wrap .woo-single-images, .single_product.quick_view_wrap .entry-summary {
    width: 100%;
}
.single_product.quick_view_wrap .entry-summary>.woo_inner {
    max-width: calc(100% - 10px);
    padding-left: 10px;
    padding-top: 10px;
}
.home-map.fl-wrap {
    height: 420px;
}
.fs-left-map-box .map-container.fw-map {
    position: relative;
    width: 100%;
    height: 100%;
	top: 0;
}
.banner-search-2.transparent {
	padding: 0 0 0 0rem;
}
.map-container.fw-map #map-main {
    position: relative;
    height: 420px;
    width: 100%;
}
.half-map .fs-inner-container {
    width: 100%;
}
.half-map .fs-content {
    padding: 2rem 1rem;
}
.nav-menu.nav-menu-social>li.login_click a {
    padding: 12px 20px;
}
.nav-menu.nav-menu-social>li.login_click{
	top:9px;
}
.lj_footer_first {
margin-bottom: 10px;
}
.style_2 .details_header .appliex_info {
    display: block;
}
.style_2 .job_employer_thumb {
    margin-bottom: 1rem;
}
ul.head_jobs_social {
    margin-bottom: 1rem;
}
.dashboard_single_course_progress {
    display: block;
    align-items: center;
    width: 100%;
}
.single_testi_caption {
    padding: 1rem;
}
.hide-small{
	display:none;
}
.hero-inner-2:after{
	display:none;
}
.job_details.style_3, .job_details.style_4 {
    margin-bottom: 1rem;
}
.page_title {
    min-height:300px;
    padding:50px 0;
}
.mg-list-thumb {
    display: block;
    float: none;
}
.dashboard-wrap {
    margin-bottom: 1rem;
}
.manage-list{
	display:block;
}
.topbar-head .topbar-left ul.tp-list {
    display: none;
}
.tp-side-menu {
    display: block;
}
.topbar-head ul li {
    padding-right: 8px;
}
ul.dropdown-menu.mlix-wrap {
    padding: 1rem 0.5rem;
}
.education_block_list_layout {
    display: block;
    width: 100%;
}
.education_block_list_layout .education_block_thumb {
    width: 100%;
	height:auto;
}
.education_block_list_layout .education_block_footer {
    padding-top: 0;
    margin: 0 !important;
}
.education_block_list_layout .education_block_body {
    padding:20px;
}
}

@media (max-width: 991px) {
.hide-91{
	display:none;
}
.show-91{
	display:block;
}
.change-logo .nav-brand.static-logo {
    display: none;
}
}
@media (max-width: 992px) {
.nav-dropdown>li>a {
    border-bottom: 1px solid #2d3454;
    color: #787fa0;
}
.nav-menu.nav-menu-social>li.login_click.search {
    display: none;
}
.filter_wraps {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}
.submenu-indicator-chevron {
    border-color: transparent #787fa0 #787fa0 transparent;
}
.nav-menu>li .nav-dropdown {
    background: transparent;
    padding: 0px;
}
.nav-menu>li>.nav-dropdown:before{
	display:none;
}
}
@media (max-width: 1023px) {
.banner_count_sec {
	display:none;
}	
.woo_product_title {
    font-size: 18px;
}
ul.woo_info li {
    margin-right:0.5rem;
    font-size: 13px;
}
.dashboard_single_course {
    display: block;
}
.dashboard_single_course_thumb {
    width: 100%;
    margin-bottom: 1rem;
}
.dashboard-navbar, .dashboard-wraper {
    padding: 1em;
}
.dashboard_single_course_thumb img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.cart_totals.checkout {
    margin-top: 1rem;
}
.checkout_coupon.checkout {
    display: block;
}
.checkout_coupon_flex {
    margin-bottom: 1rem;
}
.ed_view_box.style_3 .ed_view_features ul li {
    flex: 0 0 100%;
}
.edu_wraper .edu_title {
    font-size: 15px;
}

ul.edu_list li {
    font-size: 10px;
}
.inline_edu_wrap {
    display: block;
}
.inline_edu_first {
    margin-bottom: 1rem;
}
.nav-link {
    padding: .8rem 1rem;
}
.show-23{
	display:block;
}
.hide-23{
	display:none;
}
.featured_jb_box{
	display:none;
}
}

@media (max-width: 1199.98px) {
div#brand-slide {
    padding-top: 2rem;
}
.edu_data .title {
    font-size: 15px;
    line-height: 35px;
    margin-bottom: 0px;
}
ul.cources_facts_list li {
    padding: 5px 12px;
    font-size: 12px;
}
.cources_info_last h3 {
    font-size: 25px;
}
.jobs_info ul li {
	margin-right: 4px;
    padding:5px 10px;
}
}