button.dropdown{
    background-color: #1b2132; 
    text-align: left;
    padding: 1rem;
    width: 100%;   
}
#side-menu a:hover{
    background-color: #eb4034; 
}

.dashboard-navbar {
    height: 100vh !important;
}