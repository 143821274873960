.parent-container{
  padding-top: 80px;
  padding-bottom: 80px;
}

.parent-row{
  align-items: center;
}

.title{
  margin-bottom: 15px;
  font-size: 30px;
  font-weight: 600;
  color: rgb(45, 57, 84);
}

.highlight-text{
  color: rgb(218, 11, 78);
}

.child-row{
  margin-bottom: 24px;
}

.subtitle{
  font-weight: bold;
  font-size: 14px;
  color: rgb(45, 57, 84);
}

.content{
  font-size: 14px;
  font-weight: 400;
  color: rgb(100, 123, 156);
}

.outline-button{
  size: 'lg';
  background-color: rgba(218, 11, 78, 0.1);
  color: rgb(250, 0, 0);
  border-radius: 6px;
  border-width: 0mm;
  font-size: 16px;
  height: 54px;
}

.img-wrapper{
  width: 100%;
  height: 100%;
  max-width: 550px;
  max-height: 490px;
}