.background{
  background-color: rgba(254, 65, 53,0.1);
  max-width: 100%;
}

.parent-container{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 80px;
  padding-top: 80px;
  max-width: 540;
  align-items: center;

}
.title{
  color:rgba(16, 4, 87, 0.925);
  margin-bottom: 0%;
  max-width: 500px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}


.content{
  color: rgb(109, 109, 109);
  max-width: 500px;
  margin-bottom: 10px;
}

.seach-icon{
  width: 25px;
  height: 65px;
}

.col-wrapper{
  max-width: 500px;
}

.input-box{
  border-width: 0cm;
  padding-left: 0px;
  padding-right: 0px;
}

.input-button{
  padding-left: 0px;
  padding-right: 0px;
}

.img-wrapper{
  max-width: 500px;
  width: 100%;
  max-height: 600px;
  height: 100%;
}

.form-search{
  background: url("./../assets/custom/img/search.svg") no-repeat scroll ;
  background-size: 6%;
  background-position: 2%;
  padding-left: 50px !important;
}
